import React from 'react';
import Slider from 'react-slick';
import './massage.css'



const products = [
    { id: 800, title: "Массаж для лица",spis1:'Лимфодренажный массаж лица ',spis2:'Классический массаж лица',spis3:'Косметический массаж лица ',spis4:'Пластический(миоскульптурный) массаж лица 1 час 2500₽',spis5:'Буккальный массаж лица',spis6:'Массаж лица по Жаке ',spis7:'Точечный массаж лица',spis8:'Японский массаж лица 1 час 2500₽',spis9:'Стоунтерапия для лица',spis10:'Ультразвуковой массаж лица',spis11:'Вакуумный массаж лица', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-210'},
    { id: 801, title: "Массаж тела",spis1:'Классический массаж',spis2:'Лимфодренажный массаж ',spis3:'Антицеллюлитный массаж ',spis4:'Вакуумный массаж',spis5:'Медовый массаж 3000₽/1час',spis6:'Релаксирующий массаж',spis7:'Спортивный массаж',spis8:'Моделирующий массаж',spis9:'Точечный массаж',spis10:'Антистрессовый массаж ', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-211'},
    { id: 802, title: "Спа-массаж",spis1:'Стоун-массаж(горячими камнями) ',spis2:'Арома-массаж',spis3:'Массаж травянными мешочками',spis4:'Медовый спа-массаж',spis5:'Королевский массаж в 4-ре руки 1 час 6000₽',spis6:'Точечный массаж шиацу',spis7:'Аюрведический массаж',spis8:'Гавайский массаж ломи-ломи ',spis9:'Турецкий мыльный массаж',spis10:'Тибетский массаж с поющей чашей ',spis11:'Индийский(маслянный) массаж ',spis12:'Креольский массаж(бамбуковыми палочками)',spis13:'Испанский массаж ',spis14:'Тайский массаж', duration:'1 час',  serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-86'},
    { id: 803, title: "Детский массаж",spis1:' Детский массаж до года до 30 мин. 500₽',spis2:'Детский массаж от 1 до 3 лет',spis3:' Детский массаж от 3 до 6 лет',spis4:'Детский массаж от 6 до 14 лет до 1 часа 1500₽', duration:'30 мин.',  serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-213'},
    { id: 804, title: "Аппаратный массаж",spis1:' LPG-массаж',spis2:'Ультразвуковой массаж(кавитация)',spis3:'Дарсонвализация 20 мин. 500₽ ',spis4:'Пневмомассаж(прессотерапия) ',spis5:'Миостимуляция', duration:'30 мин.', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'],desc: '', city: '' , imgClass: 'card__img-3 card__img-search-214'},
    { id: 805, title: "Массаж в коррекции фигуры",spis1:'Лимфодренажный ',spis2:'Антицеллюлитный ',spis3:'Баночный ',spis4:'Медовый',spis5:'Липоскульптурный',spis6:' Slim-массаж',serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-215'},

];






const reviews = [
    {
        name: "Ксения",
        date: "17.05.2023",
        rating: "отлично",
        advantages: "Расслабляет",
        disadvantages: "Хочется еще",
        comment: "Решила первый раз пойти на масляный массаж. Зря не попробовала это чудо раньше...",
        adminResponse: "Спасибо за Ваш отзыв"
    },
    {
        name: "Ксения2",
        date: "17.05.2023",
        rating: "отлично",
        advantages: "Расслабляет",
        disadvantages: "Хочется еще",
        comment: "Решила первый раз пойти на масляный массаж. Зря не попробовала это чудо раньше...",
        adminResponse: "Сп210бо за Ваш отзыв"
    },
    // Добавьте другие отзывы сюда
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            ›
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            ‹
        </div>
    );
};
const massage = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart, handleAddToCartWithNotification}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Массаж в {titlee}</h1>

                <h2>
                На официальном сайте представлен актуальный обзор цен на различные виды массажа для одного или двоих одновременно в салонах Волгограда.
                <br />
                Действует скидка 10% на посещения курсом из 10 процедур.
                Разнообразие массажных техник для лица и тела, оздоровления, омоложения, похудения или глубокого расслабления.
                Проводят дипломированные специалисты с медицинским образованием.
                </h2>
            </main>

            <section className="massage-second-block">
                <h1>Наши услуги</h1>
                <div className="cards-m">

                    {products.map((product) => (
                        <div key={product.id} className="massage-card">
                            <div className={product.imgClass}></div>
                            <div style={{width: "100%"}}>
                                <h1 className="card__title">{product.title}</h1>
                                <ul className="mas-u">
                                    {product.spis1 && <li>{product.spis1}</li>}
                                    {product.spis2 && <li>{product.spis2}</li>}
                                    {product.spis3 && <li>{product.spis3}</li>}
                                    {product.spis4 && <li>{product.spis4}</li>}
                                    {product.spis5 && <li>{product.spis5}</li>}
                                    {product.spis6 && <li>{product.spis6}</li>}
                                    {product.spis7 && <li>{product.spis7}</li>}
                                    {product.spis8 && <li>{product.spis8}</li>}
                                    {product.spis9 && <li>{product.spis9}</li>}
                                    {product.spis10 && <li>{product.spis10}</li>}
                                    {product.spis11 && <li>{product.spis11}</li>}
                                    {product.spis12 && <li>{product.spis12}</li>}
                                    {product.spis13 && <li>{product.spis13}</li>}
                                    {product.spis14 && <li>{product.spis14}</li>}
                                </ul>
                                <button className="card__btn" onClick={() => handleAddToCartWithNotification(product)}>Забронировать</button>
                            </div>
                        </div>
                    ))}


                </div>
            </section>

            <main className="middle-block">
                <div className="title-block-mas">
                    <h1>3 причины выбрать нас</h1>
                    <h2>Мы работаем каждый день, чтобы сделать вашу жизнь счастливее</h2>
                </div>
            <div className="hg">
                <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>

                <div>
                    <h5>Высокое качество</h5>
                    <h6>Наши специалисты постоянно повышают свою квалификацию и участвуют в международных конференциях.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>
                <div>
                    <h5>Чистота и комфорт</h5>
                    <h6>Для нас важно, чтобы клиенты чувствовали себя комфортно. Вот почему мы всегда следим за чистотой и атмосферой наших салонов.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>
                <div>
                    <h5>Индивидуальный подход</h5>
                    <h6>Наша компания работает по принципу индивидуального подхода к каждому клиенту. Мы уважаем ваши пожелания и предпочтения.</h6>
                </div>
            </div>
            </main>

            <div className="review-slider">
            <h1>Отзывы об услуге</h1>
            <Slider {...settings}>
                {reviews.map((review, index) => (
                    <div key={index} className="review">
                        <p><strong>{review.name} {review.date}</strong></p>
                        <p>{review.rating}</p>
                        <p><strong>Достоинства:</strong> {review.advantages}</p>
                        <p><strong>Недостатки:</strong> {review.disadvantages}</p>
                        <p><strong>Комментарий:</strong> {review.comment}</p>
                        <p><strong>Ответ администратора {review.date}</strong></p>
                        <p>{review.adminResponse}</p>
                    </div>
                ))}
            </Slider>
            </div>

            <section className='fo-block'>
                <h1>Специалисты</h1>
                <h2>Опытные профессионалы своего дела!</h2>

                <div className="spec">
                    <div className="spec-card">
                        <div className="spec-img-1"></div>

                        <div className="spec-title">
                        Массаж— поистине это полезно и предотвращает от болезней органов 
                        </div>
                    </div>

                    <div className="spec-card">
                        <div className="spec-img-2"></div>

                        <div className="spec-title">
                        Эффект массажа — естественная восстанавливающая сила организма, сила жизни 
                        </div>
                    </div>

                    <div className="spec-card">
                        <div className="spec-img-3"></div>

                        <div className="spec-title">
                        Массаж во врачевании во все времена играл огромную роль, и люди давно должны были поставить памятник рукам массажиста.
                        </div>
                    </div>

                    <div className="spec-card">
                        <div className="spec-img-4"></div>

                        <div className="spec-title">
                        Наше тело всё время говорит с нами. Если бы мы только нашли время послушать
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default massage