import React, { useState, useEffect } from 'react';
import './Cart.css';
import { NavLink } from 'react-router-dom';
import emailjs from 'emailjs-com';

const Cart = ({ cartItems, handleRemoveFromCart, products }) => {
    const [total, setTotal] = useState(0);
    const [paymentMethod, setPaymentMethod] = useState('feedback'); // State for selected payment method

    useEffect(() => {
        const calculateTotal = () => {
            const totalPrice = cartItems.reduce((acc, item) => acc + parseFloat(item.price), 0);
            setTotal(totalPrice);
        };
        calculateTotal();
    }, [cartItems]);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formData = new FormData(e.target);

        // Сбор информации о товарах в корзине
        const cartDetails = cartItems.map(item => `${item.title} - ${item.price} p.`).join('\n');

        const templateParams = {
            name: formData.get('name'),
            tel: formData.get('tel'),
            cartDetails: cartDetails,
            total: `${total} p.`,
            city: formData.get('city'),  // Если город вводится пользователем
        };

        emailjs.send('service_9rthmgq', 'template_zxcsx6s', templateParams, 'RFunDjpzDS4YPPTtu')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                alert('Заявка успешно отправлена!');
            }, (error) => {
                console.error('FAILED...', error);
                alert('Произошла ошибка при отправке заявки.');
            });
    };

    return (
        <div className="cart-page">
            <h2>Корзина</h2>

            <div className='main-container__cart'>
                <div className='lsc'>
                    <div className='first-card'>
                        <h2>Вы выбрали:</h2>
                        {cartItems.length === 0 ? (
                            <p>Ваша корзина пуста.</p>
                        ) : (
                            <div>
                                <ul>
                                    {cartItems.map((item, index) => (
                                        <li key={index} className="cart-item">
                                            <div className={`cart-item-image ${item.imgClass}`}></div>
                                            <div>
                                                <div className="item-info">
                                                    <span className="item-title">{item.title}</span>
                                                    <span className="item-price">Цена - {item.price}</span>
                                                </div>
                                                <button className="remove-button" onClick={() => handleRemoveFromCart(index)}>Удалить</button>
                                            </div>
                                        </li>

                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>

                    <div className='second-card'>
                        <h2>Стоимость:</h2>

                        <hr/>

                        <div><p>Итого</p><strong>{total} ₽</strong></div>
                    </div>
                </div>

                <div className='oplata-card'>
                    <h2>Выберите способ оплаты</h2>

                    <div>
                        <h3 onClick={() => setPaymentMethod('feedback')} style={{ cursor: 'pointer' }}>Обратная связь</h3>
                        <h3 onClick={() => setPaymentMethod('online')} style={{ cursor: 'pointer' }}>Онлайн оплата</h3>
                    </div>

                    {paymentMethod === 'feedback' && (
                        <form className='oplata-form' onSubmit={handleFormSubmit}>
                            <input name="name" placeholder='Введите имя' type='text' required />
                            <input name="tel" placeholder='Введите номер телефона' type='tel' required />
                            <input name="city" placeholder='Введите город' type='text' required /> {/* Если нужно ввести город */}
                            <hr />
                            <div className='chicki'>
                                <input type='checkbox' required />
                                <p>Я даю согласие на <NavLink to="/polyt">обработку персональных данных</NavLink></p>
                            </div>
                            <button type="submit">Оставить заявку</button>
                        </form>
                    )}

                    {paymentMethod === 'online' && (
                        <div className='oplata-online'>
                            {/* Add your online payment form here */}
                            <p>Онлайн оплата еще не реализована.</p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Cart;
