import React from 'react';
import Slider from 'react-slick';
import './massage.css'

const products = [
    { id: 216, title: "Массаж для двоих",spis1:'Классический массаж тела  ',spis2:'Оздоровительный массаж',spis3:'Лимфодренажный массаж ',spis4:'Антицеллюлитный массаж ',spis5:'Вакуумный массаж ',spis6:'Медовый массаж 1 час 6000₽ ',spis7:'Релаксирующий массаж тела',spis8:'Спортивный массаж',spis9:'Моделирующий массаж тела  ',spis10:'Точечный массаж тела',spis11:'Антистрессовый массаж ', duration:'1час',  serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-216'},
    { id: 217, title: "Массаж-спа для двоих",spis1:'Тайский массаж ',spis2:'Испанский массаж ',spis3:'Креольский массаж(бамбуковыми палочками) ',spis4:'Индийский(маслянный) массаж',spis5:'Тибетский массаж с поющей чашей',spis6:'Турецкий мыльный массаж ',spis7:'Гавайский массаж ломи-ломи',spis8:'Аюрведческий спа-массаж',spis9:'Точечный массаж шиацу ',spis10:'Королевский массаж в 4 руки 1 час 12000₽',spis11:'Стоун-массаж(горячими камнями) ', spis12:'Арома-массаж',spis13:'Массаж травянными мешочками',spis14:'Медовый спа-массаж',spis15:'Relax-массаж',duration:'1 час',  serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-217'},
    { id: 218, title: "Массаж лица для двоих",spis1:'Лимфодренажный массаж лица 30 мин.4000₽  ',spis2:'Классический массаж лица',spis3:'Косметический массаж лица ',spis4:'Пластический(миоскульптурный) массаж лица 1 час 4000₽',spis5:'Буккальный массаж лица 30 мин.4000₽',spis6:'Массаж лица по Жаке',spis7:'Точечный массаж лица',spis8:'Хиромассаж лица 30 мин.4000₽',spis9:'Стоунтерапия для лица',spis10:'Ультразвуковой массаж лица ',spis11:'Вакуумный массаж лица', duration:'30 мин.',  serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-218'},
    { id: 219, title: "Аппаратный массаж",spis1:'LPG-массаж 35 мин.6000₽ ',spis2:'Ультразвуковой массаж(кавитация) ',spis3:'Дарсонвализация 20 мин.1000₽ ',spis4:'Пневмомассаж(прессотерапия) ',spis5:'Миостимуляция ', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-219'},
    { id: 220, title: "Массаж для похудения на двоих",spis1:'Лимфодренажный 1 час 5000₽ ',spis2:'Антицеллюлитный',spis3:'Баночный 1 час 5000₽ ',spis4:'Медовый ',spis5:'Липоскульптурный 1 час 7000₽',spis6:'Slim-массаж ', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-220'},
];




const massageTwo = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart, handleAddToCartWithNotification}) => {
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Массаж на двоих в {titlee}</h1>

                <h2>
                Массаж для двоих - это удовольствие, которое сложно забыть, а совместное времяпровождение еще больше бодрит и вдохновляет на приключения. Воспользоваться услугой массаж на двоих можно в любой день, а как здорово преподнести столь оригинальный подарок с эмоциями для своей второй половинки по случаю какого-либо знаменательного события.
                <br />
                <p>Время столь неуловимо в нашем современном мире, а так хочется наслаждаться каждой минутой, проведенной с любимыми. Не ограничивайте себя в желаниях, позвольте себе праздничный релакс на двоих и воспользуйтесь супер популярной услугой от нашего салона красоты и здоровья - массаж для двоих! Наслаждайтесь временем, проведенным вместе, одновременно с расслабляющими эффектами от наших опытных дипломированных мастеров.<br />
                Это уникальная программа, где теперь вам не придется скучать в одиночку. Программа включает ряд массажных мероприятий на двоих. Для наших клиентов предоставляется уникальная программа, которая включает в себя комбинацию основных приемов, утонченных, нежных мануальных тактик, равномерного и длительного успокаивающего воздействия, которое снижает чувствительность нервной системы и приводит к разогреву, ускорению обмена веществ и снятию отеков. В программе удачно сочетаются между собой различные методики:<br />
                Расслабляющий массаж - это массажная техника, цель которой заключается в том, чтобы снять мышечное напряжение, оказать воздействие на нервную систему и кровообращение. Согревающий массаж направлен на продолжительный разогрев с активными вибрационными техниками с целью усиления кровотока. Элементы лимфодренажного масажа с целью оздоровления и выведения токсинов, для придания бодрости, энергии и сил. Исключительный релакс, который позволит клиенту почувствовать себя отдохнувшим и забыть о всех повседневных заботах.<br />

                Сотрудники нашего салона красоты знают как оставить яркие впечатления у своих клиентов, а поэтому с нетерпением ждут вас на эту незабываемую процедуру! Вы будете избалованы этим дружеским и интимным опытом и обязательно пожелаете вернуться к нам еще, ведь удовольствие - это то, в чем не стоит себе отказывать!</p>
                </h2>
            </main>

            <section className="massage-second-block">
                <h1>Наши услуги</h1>
                <div className="cards-m">

                    {products.map((product) => (
                        <div key={product.id} className="massage-card">
                            <div className={product.imgClass} style={{flex:'inherit'}}></div>
                            <div style={{width:"100%"}}>
                                <h1 className="card__title">{product.title}</h1>
                                <ul className="mas-u">
                                    {product.spis1 && <li>{product.spis1}</li>}
                                    {product.spis2 && <li>{product.spis2}</li>}
                                    {product.spis3 && <li>{product.spis3}</li>}
                                    {product.spis4 && <li>{product.spis4}</li>}
                                    {product.spis5 && <li>{product.spis5}</li>}
                                    {product.spis6 && <li>{product.spis6}</li>}
                                    {product.spis7 && <li>{product.spis7}</li>}
                                    {product.spis8 && <li>{product.spis8}</li>}
                                    {product.spis9 && <li>{product.spis9}</li>}
                                    {product.spis10 && <li>{product.spis10}</li>}
                                    {product.spis11 && <li>{product.spis11}</li>}
                                    {product.spis12 && <li>{product.spis12}</li>}
                                    {product.spis13 && <li>{product.spis13}</li>}
                                    {product.spis14 && <li>{product.spis14}</li>}
                                </ul>
                                <button className="card__btn" onClick={() => handleAddToCartWithNotification(product)}>Забронировать</button>
                            </div>
                        </div>
                    ))}



                </div>
            </section>

            <main className="main-block">
                <h1>Массаж для двоих в {titlee}</h1>

                <h2>
                    Парный массаж– для отдыха и релаксации двоим одновременно в салонах Волгограда. Сочетание романтической обстановки и чувственная атмосфера наполненная ласкающими ароматами арома-масел, для уединения вместе с любимым человеком. Такой вид массажа для пар которые дорожат своим временем и готовы внести в отношения новые впечатления радуя партнера необычным подарком. Сеанс массажа для пары – интересное решение к 8 марта, дню всех влюбленных, годовщине свадьбы или дню рождения.
                    <br />
                    Процедуры для двоих проводят в одном помещении, что позволяет парам наслаждаться новыми впечатлениями напротив друг друга.
                    <br />
                    Во время проведения программ для отдыха и оздоровления звучит специально подобранная расслабляющая музыка, мастера предлагают выбрать натуральную косметику для проведения массажа, чтобы угодить любому Вашему желанию и усилить эффект релаксации.
                    <br />
                    Массаж специально подобран для одновременного получения удовольствия и наслаждения отдыхом вместе с любимым человеком, подругой или родственником.
                    <br />
                    Новые эмоции для семейной пары, готовой окунуться в расслабляющую атмосферу в окружении спа мастеров. Делитесь эмоциями с тем, кто вам дорог, или погружайтесь в оздоровительные процедуры и глубокую релаксацию всего тела.Во время массажа отступит любая усталость, наступит глубокое расслабление мышц всего тела, что приблизит Вас к душевному равновесию.
                    <br />
                    Разнообразие массажей и натуральных косметических средств во время процедур для глубокого расслабления всего тела, принесут приятные эмоции и надолго запомнятся.
                    <br />
                    Разделить удовольствие с любимым человеком на экзотическом spa-массаже, наслаждаясь расслабляющей музыкой и арома-релаксацией, в сочетании с продуманными техниками массажа для мужчины и женщины, которые оставят самые теплые и приятные впечатления. Подарите радость и наслаждение разделив новые ощущения с любимым человеком!
                    <br />
                    Вы можете выбрать один из видов массажа для двоих, или сделать индивидуальный выбор подходящих масел и массажа во время проведения.
                    <br />
                    У нас Вы обретёте гармонию и умиротворение, насладитесь экзотическими ароматами натуральных косметических средств и нежными прикосновениями. Проведя спа-день или романтический отдых для двоих на выходные дни, Вас порадует смена обстановки и новые впечатления, которые надолго оставят радостные эмоции и воспоминания.<br />
                    На официальном сайте представлен обзор цен на массаж для двоих в Волгограде,где двое смогут сделать парный массаж в салонах Волгограда.
                    Стоимость массажа для двоих лица и отдельных зон спины, шеи, ног или рук, расценки для пары на аппаратный и ручной massage в spa-салоне, для мужчины, женщины или вдвоём одновременно.<br />
                    Прейскурант массажистов, с расценками на разнообразные массажные техники паре, с указанием длительности сеанса и зон воздействия для оздоровления, омоложения, похудения или глубокого расслабления.<br />
                    Слишком дешёвый ценник за сеанс, может предложить новичок в этом деле, чтобы набить руку и набраться практического опыта.
                </h2>
            </main>

        </div>
    );
}

export default massageTwo