import React from 'react';
import Slider from 'react-slick';
import './massage.css'

const products = [
    { id: 900, title: "Косметология для лица",spis1:'Ультразвуковая чистка ',spis2:'Механическая чистка 1 час. 3000₽',spis3:'Комбинированная чистка 1.30 часа 3500₽ ',spis4:'Аппаратный массаж лица 30 мин. от 1250₽',spis5:'Косметический массаж лица 30 мин.от 1250₽',spis6:'Скульптурный-пластический массаж лица 1 час 3000₽ ',spis7:'RF-лифтинг',spis8:'Дарсонвализация лица 15-20 мин. 500₽',spis9:'Дарсонвализация волосистой части головы 15-20 мин. 500₽',spis10:'Ультрафонофорез',spis11:'Алмазный пилинг',spis12:'Химический пилинг от 1500₽',spis13:'Карбокситерапия 30 мин. 3500₽',spis14:'Уходовые маски для лица 10-30 мин. от 500₽',spis15:'Демакияж лица 10 мин. 200₽',spis16:'Биоревитализация от 2500₽',spis17:'Мезотерапия ', serviceType:'Спа',forWhom: ['Для женщин','Для мужчин','Для двоих'], price: 1500, desc: '', city: '' , imgClass: 'card__img-3 card__img-search-900'},
    { id: 901, title: "Косметология для тела",spis1:'Кавитация ',spis2:'LPG-массаж 35 мин. от 2500₽',spis3:'RF-лифтинг',spis4:'Липолитики от 1500₽', serviceType:'Спа',forWhom: ['Для женщин','Для мужчин','Для двоих'], price: 1500, desc: '', city: '' , imgClass: 'card__img-3 card__img-search-901'},
    { id: 902, title: "Депиляция воском",spis1:'Ноги (полностью) 30-50 мин.1500₽ ',spis2:'Голень 20-30 мин.1000₽',spis3:'Руки (полностью) 30 мин.600₽ ',spis4:'Руки (до локтя) ',spis5:'Подмышки ',spis6:'Бикини (классическое) 30-50 мин.100₽ ',spis7:'Бикини (глубокое) 40-60 мин.1500₽',spis8:'Живот (полоска) 5 мин.300₽',spis9:'Живот (полностью)',spis10:'Лицо 7-10 мин.500₽', serviceType:'Спа',forWhom: ['Для женщин','Для мужчин','Для двоих'],price: 500, desc: '', city: '' , imgClass: 'card__img-3 card__img-search-902'},
];









const cosmetol = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart}) => {
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Косметология в {titlee}</h1>

                <h2>
                Актуальные цены на косметологические услуги в Волгограде для профессионального ухода по показаниям, устранения и профилактики возрастных и эстетических изменений.
                <br />
                Опытные косметологи подберут подходящие процедуры и индивидуальные уходы по показаниям для лучшего результата.
                </h2>
            </main>

            <section className="massage-second-block">
                <h1>Наши услуги в {titlee}</h1>
                <div className="cards-m">
                    {products.map((product) => (
                        <div key={product.id} className="thride-block__card2 lololo" style={{width: "100%"}}>
                            <div className={product.imgClass} style={{maxWidth: "700px"}}></div>
                            <div>
                                <h1 className="card__title">{product.title}</h1>
                                <ul>
                                    {product.spis1 && <li>{product.spis1}</li>}
                                    {product.spis2 && <li>{product.spis2}</li>}
                                    {product.spis3 && <li>{product.spis3}</li>}
                                    {product.spis4 && <li>{product.spis4}</li>}
                                    {product.spis5 && <li>{product.spis5}</li>}
                                    {product.spis6 && <li>{product.spis6}</li>}
                                    {product.spis7 && <li>{product.spis7}</li>}
                                    {product.spis8 && <li>{product.spis8}</li>}
                                    {product.spis9 && <li>{product.spis9}</li>}
                                    {product.spis10 && <li>{product.spis10}</li>}
                                    {product.spis11 && <li>{product.spis11}</li>}
                                    {product.spis12 && <li>{product.spis12}</li>}
                                    {product.spis13 && <li>{product.spis13}</li>}
                                    {product.spis14 && <li>{product.spis14}</li>}
                                </ul>
                                <h2 className="card__subtitle">{product.duration}</h2>
                                <h2 className="card__subtitle">{product.desc}</h2>
                                <h2 className="card__subtitle">Цена - {product.price}</h2>
                                <h2 className="card__subtitle">{product.city}</h2>
                                <button className="card__btn" onClick={() => handleAddToCart(product)}>Добавить в корзину</button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <main className="middle-block">
                <div className="title-block-mas">
                    <h1>3 причины выбрать нас</h1>
                    <h2>Мы работаем каждый день, чтобы сделать вашу жизнь счастливее</h2>
                </div>
            <div className="hg">
                <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>

                <div>
                    <h5>Высокое качество</h5>
                    <h6>Наши специалисты постоянно повышают свою квалификацию и участвуют в международных конференциях.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>
                <div>
                    <h5>Чистота и комфорт</h5>
                    <h6>Для нас важно, чтобы клиенты чувствовали себя комфортно. Вот почему мы всегда следим за чистотой и атмосферой наших салонов.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>
                <div>
                    <h5>Индивидуальный подход</h5>
                    <h6>Наша компания работает по принципу индивидуального подхода к каждому клиенту. Мы уважаем ваши пожелания и предпочтения.</h6>
                </div>
            </div>
            </main>
        </div>
    );
}

export default cosmetol