import React, { useState } from 'react';
import './Header.css';
import { NavLink } from 'react-router-dom';
import img from "../../image/HomePage/logonew.jpg";

const BurgerMenu = ({ links }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [openSubMenus, setOpenSubMenus] = useState({});

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = (event, href) => {
        setIsOpen(false);
    };

    const toggleSubMenu = (index) => {
        setOpenSubMenus({
            ...openSubMenus,
            [index]: !openSubMenus[index]
        });
    };
    const SocialLink = ({ href, title, svgPath }) => (
        <div className="t188__sociallinkimg">
            <a href={href} target="_blank" rel="nofollow">
                <svg className="t-sociallinks__svg" version="1.1" xmlns="http://www.w3.org/2000/svg"  width="48px" height="48px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" xmlSpace="preserve">
                    <desc>{title}</desc>
                    <path  d={svgPath} />
                </svg>
            </a>
        </div>
    );

    return (
        <div className="burger-menu">
            <div className={`burger-icon ${isOpen ? 'open' : ''}`} onClick={toggleMenu}>
                <div className="line1"></div>
                <div className="line2"></div>
                <div className="line3"></div>
            </div>
            <nav className={`menu ${isOpen ? 'open' : ''}`}>
                <button className="close-button" onClick={toggleMenu}>&times;</button>
                <div className="logos"></div>
                <ul className="menu-list">
                    {links.map((link, index) => (
                        <li key={index}>
                            {link.subLinks ? (
                                <>
                                    <a
                                        href={link.href}
                                        onClick={(e) => {
                                            e.preventDefault();
                                            toggleSubMenu(index);
                                        }}
                                        className="accordion-link"
                                    >
                                        {link.label}
                                        <span className={`accordion-icon ${openSubMenus[index] ? 'open' : ''}`}>
                                            {openSubMenus[index] ? '↑' : '↓'}
                                        </span>
                                    </a>
                                    {openSubMenus[index] && (
                                        <ul className="sub-menu">
                                            {link.subLinks.map((subLink, subIndex) => (
                                                <li key={subIndex}>
                                                    <NavLink
                                                        to={subLink.href}
                                                        onClick={(e) => handleLinkClick(e, subLink.href)}
                                                    >
                                                        {subLink.label}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </>
                            ) : (
                                <NavLink
                                    to={link.href}
                                    onClick={(e) => handleLinkClick(e, link.href)}
                                >
                                    {link.label}
                                </NavLink>
                            )}
                        </li>
                    ))}

                    <div id="rec328547330" className="r t-rec t-rec_pt_0 t-rec_pb_15" style={{ paddingTop: '0px', paddingBottom: '15px', marginTop:'16px' }} data-record-type="212">
                        <div className="t188">
                            <div className="t-container_100">
                                <div className="t188__wrapone">
                                    <div className="t188__wraptwo">
                                        <a style={{    width: 36,
                                            height: 36}} href="https://www.instagram.com/alex_estetica" title="Instagram" ><img style={{    width: 36,
                                            height: 36}} src='../instagram-logo-facebook-2-svgrepo-com.svg'/></a>
                                        <a style={{    width: 36,
                                            height: 36}} href="https://www.instagram.com/alex_estetica" title="Instagram" ><img style={{    width: 36,
                                            height: 36}} src='../tel.png'/></a>
                                        <a style={{    width: 36,
                                            height: 36}} href="https://www.instagram.com/alex_estetica" title="Instagram" ><img style={{    width: 36,
                                            height: 36}} src='../vk.png'/></a>
                                        <a style={{    width: 36,
                                            height: 36}} href="https://www.instagram.com/alex_estetica" title="Instagram" ><img style={{    width: 36,
                                            height: 36}} src='../telegram.png'/></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ul>
            </nav>
        </div>
    );
};

export default BurgerMenu;
