import React from 'react';
import { useLocation } from 'react-router-dom';

const pages = [
    { path: '/', name: 'О салоне' },
    { path: '/massage', name: 'Массаж' },
    { path: '/massage', name: 'Массаж цена' },
    { path: '/massage', name: 'Массаж для женщины' },
    { path: '/massage', name: 'Массаж для мужчины' },
    { path: '/massageTwo', name: 'Массаж для двоих' },
    { path: '/massageface', name: 'Массаж лица' },
    { path: '/massage', name: 'Массаж тела' },
    { path: '/massagechildren', name: 'Детский массаж' },
    { path: '/massageanti', name: 'Антицеллюлитный массаж' },
    { path: '/massagelpg', name: 'LPG массаж' },
    { path: '/abon', name: 'Абонементы' },
    { path: '/cosmet', name: 'Косметология' },
    { path: '/spa', name: 'Спа программы' },
    { path: '/spatwo', name: 'Спа для двоих' },
    { path: '/spa', name: 'Спа для женщины' },
    { path: '/spa', name: 'Спа для мужчины' },
    { path: '/massage', name: 'Спа массаж' },
    { path: '/tatu', name: 'Тату' },
    { path: '/obuc', name: 'Обучение массажу' },
    { path: '/obucc', name: 'Обучение косметологии' },
    { path: '/obuct', name: 'Обучение тату и татуаж' },
    { path: '/podarok', name: 'Подарочные сертификаты' },
];

const SearchPage = () => {
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('query').toLowerCase();

    const results = pages.filter(page => page.name.toLowerCase().includes(query));

    return (
        <div className='search-block'>
            <h2>Результаты поиска для "{query}"</h2>
            {results.length > 0 ? (
                <ul>
                    {results.map((result, index) => (
                        <li key={index}>
                            <a href={result.path}>{result.name}</a>
                        </li>
                    ))}
                </ul>
            ) : (
                <p>Ничего не найдено</p>
            )}
        </div>
    );
};

export default SearchPage;
