import React, { useState } from 'react';
import Slider from 'react-slick';
import './massage.css'
const products = [
    { id: 915, title: "Обучение массажу", price: "по договоренности", desc: '', city: '' , imgClass: 'card__img-5 obuc-img915'},
]
const courses = [
    {
      title: 'Курсы Детского массажа',
      content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Анатомия ребёнка;</li>
            <li>Показания противопоказания;</li>
            <li>Базовые комплексы массажа для детей от 1 месяца до 1 года, с 3 до 6 и от 7 до 10 лет;</li>
            <li>Массаж и гимнастика для детей от рождения до 3 месяцев, от 3 до 6 и от 6 до 12 месяцев;</li>
            <li>Требования к массажисту и массируемому;</li>
            <li>Рекомендации по работе и общению с ребёнком от практикующих мастеров;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Приёмы детского массажа;</li>
            <li>Детский массаж по частям тела;</li>
            <li>Массаж и гимнастика при заболеваниях и дисфункциях ЖКТ у детей первого года жизни (колики, запоры);</li>
            <li>Массаж и гимнастика при синдроме мышечной гипотонии, гипертонии, асимметрии мышечного тонуса, врождённой мышечной кривошеи, бронхите, пневмонии, перкуссионно-дренажный массаж;</li>
            <li>Массаж и гимнастика при врождённых и приобретённых деформациях стопы: плоскостопии, врождённой косолапости, плоско-вальгусной установке ступней, Х-образном и О-образном искривлении;</li>
            <li>Изучение техники выполнения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 72 ак.часа</p>
          <p><strong>Заочно-дистанционно 10000₽</strong></p>
          <p><strong>Очно в группе 18000₽</strong></p>
          <p><strong>Очно-индивидуально 37000₽</strong></p>
        </>
      )
    },
    { title: 'Курс Медицинский массаж',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Анатомия и физиология для «Специалиста по медицинскому массажу»;</li>
            <li>правовое обеспечение профессиональной деятельности «Специалиста по медицинскому массажу»;</li>
            <li>инфекционная безопасность и инфекционный контроль;</li>
            <li>показания противопоказания для Медицинского массажа;</li>
            <li>Основы анатомии, физиологии и биомеханики человека;</li>
            <li>Общая методика и техника классического массажа;</li>
            <li>Правила построения процедуры и курса медицинского массажа;</li>
            <li>Приемы медицинского массажа, их физиологическое действие на пациента;</li>
            <li>требования к массажисту и массируемому;</li>
            <li>разбор вопросов с преподавателем;</li>
            <li>проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Проведение обследования пациента с целью определения методики проведения медицинского массажа;</li>
            <li>Выполнение медицинского массажа с учетом индивидуальных особенностей и возраста пациента и контроль его эффективности;</li>
            <li>Массаж при заболеваниях опорно – двигательного аппарата;</li>
            <li>Массаж при заболеваниях желудочно – кишечного тракта;</li>
            <li>Массаж при заболеваниях почек и мочевыделительного тракта;</li>
            <li>Массаж при заболеваниях сердечно – сосудистой системы;</li>
            <li>Массаж при заболеваниях периферической нервной системы;</li>
            <li>Массаж при заболеваниях центральной нервной системы;</li>
            <li>Массаж при заболеваниях женских половых органов;</li>
            <li>Массаж при заболеваниях периферических сосудов;</li>
            <li>Массаж волосистой части головы;</li>
            <li>Вакуумный массаж;</li>
            <li>изучение техники выполнения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 288 ак.часов</p>
          <p><strong>Очно в группе 49000₽</strong></p>
          <p><strong>Очно-индивидуально 79000₽</strong></p>
        </>
      ) },
    { title: 'Курсы Классического массажа лица',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>введение в анатомические и физиологические особенности;</li>
            <li>показания противопоказания;</li>
            <li>влияние массажа;</li>
            <li>подготовка к массажу;</li>
            <li>асептика и антисептика;</li>
            <li>требования к массажисту и массируемому;</li>
            <li>разбор вопросов с преподавателем;</li>
            <li>проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Приёмы в классическом массаже лица;</li>
            <li>Изучение техники выполнения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 18 ак.часа</p>
          <p><strong>Заочно-дистанционно 5000₽</strong></p>
          <p><strong>Очно в группе 9000₽</strong></p>
          <p><strong>Очно-индивидуально 18000₽</strong></p>
        </>
      ) },
    { title: 'Курсы Пластического массажа лица',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Косметические препараты, применяемые до и вовремя процедуры пластический массаж лица. Алгоритм процедуры пластический массаж лица;</li>
            <li>Понятие процедуры пластический массаж лица;</li>
            <li>Показания, противопоказания к процедуре пластический массаж лица;</li>
            <li>Изучение техники выполнения;</li>
            <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Техника выполнения;</li>
            <li>Отработка практического навыка.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 18 ак.часа</p>
          <p><strong>Заочно-дистанционно 5000₽</strong></p>
          <p><strong>Очно в группе 9000₽</strong></p>
          <p><strong>Очно-индивидуально 19000₽</strong></p>
        </>
      ) },
    { title: 'Курсы Буккального массажа лица',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Косметические препараты, применяемые до и вовремя процедуры Буккальный массаж лица. Алгоритм процедуры Буккальный массаж лица;</li>
            <li>Понятие процедуры Буккальный массаж лица;</li>
            <li>Показания, противопоказания к процедуре Буккального массажа лица;</li>
            <li>Изучение техники выполнения;</li>
            <li>Требования к массажисту и массируемому;</li>
            <li>Рекомендации по работе и общению с ребёнком от практикующих мастеров;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Техника выполнения;</li>
            <li>Отработка практического навыка.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 18 ак.часа</p>
          <p><strong>Заочно-дистанционно 5000₽</strong></p>
          <p><strong>Очно в группе 9000₽</strong></p>
          <p><strong>Очно-индивидуально 19000₽</strong></p>
        </>
      ) },
    { title: 'Курсы Японского массажа лица',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Косметические препараты, применяемые до и вовремя процедуры Японского массажа лица. Алгоритм процедуры Японского массаж лица;</li>
            <li>Понятие процедуры Японский массаж лица;</li>
            <li>Показания, противопоказания к процедуре Японский массаж лица;</li>
            <li>Изучение техники выполнения;</li>
            <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Техника выполнения;</li>
            <li>Отработка практического навыка.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 18 ак.часа</p>
          <p><strong>Заочно-дистанционно 5000₽</strong></p>
          <p><strong>Очно в группе 9000₽</strong></p>
          <p><strong>Очно-индивидуально 19000₽</strong></p>
        </>
      ) },
    { title: 'Курсы массажа Шиацу',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>История Японского массажа Шиацу;</li>
            <li>Илияние массажа на локальные зоны, органы и системы человека;</li>
            <li>Требования к массажисту и массируемому;</li>
            <li>Тайскогоребования к помещению;</li>
            <li>Показания и противопоказания;</li>
            <li>Японский массаж Шиацу в Spa;</li>
            <li>Массаж Шиацу для похудения, оздоровления, расслабления, anti-age;</li>
            <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Приёмы в массаже Шиацу;</li>
            <li>Мастер-класс;</li>
            <li>Изучение техники выполнения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 18 ак.часа</p>
          <p><strong>Заочно-дистанционно 5000₽</strong></p>
          <p><strong>Очно в группе 9000₽</strong></p>
          <p><strong>Очно-индивидуально 18000₽</strong></p>
        </>
      ) },
    { title: 'Курсы Антицеллюлитного массажа',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Введение в анатомические и физиологические особенности;</li>
            <li>Влияние массажа на локальные зоны, кожу и подкожно-жировую клетчатку, органы и системы человека;</li>
            <li>Требования к массажисту и массируемому;</li>
            <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>Пожалуйроверка усвоенного материала.</li>
            <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Приёмы антицеллюлитного массажа;</li>
            <li>Изучение техники выполнения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 72 ак.часа</p>
          <p><strong>Заочно-дистанционно 10000₽</strong></p>
          <p><strong>Очно в группе 18000₽</strong></p>
          <p><strong>Очно-индивидуально 37000₽</strong></p>
        </>
      ) },
    { title: 'Курсы классического массажа',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Введение в анатомию (органы и системы человека: кожа, мышцы, скелет, лимфатическая и кровеносная системы, нервная система, суставы и связки, органы брюшной полости);</li>
            <li>Влияние массажа на органы и системы человека;</li>
            <li>Требования к массажисту и массируемому;</li>
            <li>Требования к помещению;</li>
            <li>Уход за руками;</li>
            <li>Профилактика профзаболеваний массажиста;</li>
            <li>Юридические аспекты профессии массажиста;</li>
            <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Приемы классического массажа» (поглаживание, растирание, разминание, выжимание, вибрация);</li>
            <li>Изучение техники выполнения;</li>
            <li>Отработка практических навыков.</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 72 ак.часа</p>
          <p><strong>Заочно-дистанционно 9000₽</strong></p>
          <p><strong>Очно в группе 18000₽</strong></p>
          <p><strong>Очно-индивидуально 37000₽</strong></p>
        </>
      ) },
    { title: 'Курсы Спа-специалист',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Что такое SPA;</li>
            <li>Показания противопоказания;</li>
            <li>Влияние программ на локальные зоны, органы и системы человека;</li>
            <li>Требования к массажисту и массируемому;</li>
            <li>Тайскогоребования к помещению;</li>
            <li>Показания и противопоказания;</li>
            <li>Формирование SPA-программы по показаниям;</li>
            <li>Обзор косметических средств для SPA;</li>
            <li>SPA-уход в домашних условиях;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Мастер-класс;</li>
            <li>Детский массаж по частям тела;</li>
            <li>Приёмы в Испанском, тайском, креольском(бамбуковыми палочками), стоунтерапии(массаж камнями), Индийском, Шиацу;</li>
            <li>Изучение техник выполнения;</li>
            <li>Техники энергосбережения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 72 ак.часа</p>
          <p><strong>Заочно-дистанционно 12000₽</strong></p>
          <p><strong>Очно в группе 20000₽</strong></p>
          <p><strong>Очно-индивидуально 39000₽</strong></p>
        </>
      ) },
      { title: 'Курсы Тайского массажа',
      content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Введение в анатомические и физиологические особенности;</li>
            <li>Показания противопоказания;</li>
            <li>Влияние массажа на локальные зоны, органы и системы человека;</li>
            <li>Требования к массажисту и массируемому;</li>
            <li>Требования к помещению;</li>
            <li>Показания и противопоказания;</li>
            <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Приёмы воздействия на энергетические линии тела — меридианы и биологически активные точки тела человека;</li>
            <li>Мастер-класс;</li>
            <li>Массаж и гимнастика при заболеваниях и дисфункциях ЖКТ у детей первого года жизни (колики, запоры);</li>
            <li>Изучение техники выполнения;</li>
            <li>техники энергосбережения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 16 ак.часа</p>
          <p><strong>Заочно-дистанционно 5000₽</strong></p>
          <p><strong>Очно в группе 9000₽</strong></p>
          <p><strong>Очно-индивидуально 18000₽</strong></p>
        </>
      ) },
        { title: 'Курсы Тибетского массажа',
        content: (
            <>
              <p><strong>Программа курса:</strong></p>
              <p><strong>Теоретическая часть:</strong></p>
              <ul>
                <li>Косметические средства, применяемые до и вовремя процедуры Тибетского массажа. Алгоритм проведения Тибетского массажа;</li>
                <li>Понятие процедуры Тибетский массаж;</li>
                <li>Показания, противопоказания к процедуре Тибетского массажа;
                изучение техники выполнения;</li>
                <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
                <li>Разбор вопросов с преподавателем;</li>
                <li>Проверка усвоенного материала.</li>
              </ul>
              <p><strong>Практическая часть:</strong></p>
              <ul>
                <li>Техника выполнения;</li>
                <li>Отработка практического навыка.</li>
              </ul>
              <p><strong>Продолжительность:</strong> 16 ак.часа</p>
              <p><strong>Заочно-дистанционно 5000₽</strong></p>
              <p><strong>Очно в группе 9000₽</strong></p>
              <p><strong>Очно-индивидуально 18000₽</strong></p>
            </>
          ) },
          { title: 'Курсы  Строунтерапии',
          content: (
            <>
              <p><strong>Программа курса:</strong></p>
              <p><strong>Теоретическая часть:</strong></p>
              <ul>
                <li>Понятие стоунтерапии, обзор пород камней и их свойства;</li>
                <li>Влияние массажа на локальные зоны, органы и системы человека;</li>
                <li>Требования к массажисту и массируемому;</li>
                <li>Тайскогоребования к помещению;</li>
                <li>Показания и противопоказания;</li>
                <li>Стоунтерапия в лечебно-профилактических программах;</li>
                <li>Стоунтерапия в Spa;</li>
                <li>Стоунтерапия в коррекции фигуры;</li>
                <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
                <li>Разбор вопросов с преподавателем;</li>
                <li>Проверка усвоенного материала.</li>
              </ul>
              <p><strong>Практическая часть:</strong></p>
              <ul>
                <li>Приёмы в стоунтерапии;</li>
                <li>Мастер-класс;</li>
                <li>Изучение техники выполнения;</li>
                <li>Отработка практических навыков.</li>
              </ul>
              <p><strong>Продолжительность:</strong> 16 ак.часа</p>
              <p><strong>Заочно-дистанционно 5000₽</strong></p>
              <p><strong>Очно в группе 9000₽</strong></p>
              <p><strong>Очно-индивидуально 17000₽</strong></p>
            </>
          ) },
            { title: 'Курсы Креольского массажа',
            content: (
                <>
                  <p><strong>Программа курса:</strong></p>
                  <p><strong>Теоретическая часть:</strong></p>
                  <ul>
                    <li>История Креольского массажа;</li>
                    <li>Влияние массажа на локальные зоны, органы и системы человека;</li>
                    <li>Требования к массажисту и массируемому;</li>
                    <li>Требования к помещению;</li>
                    <li>Показания и противопоказания;</li>
                    <li>Обзор средств для проведения Креольского массажа;</li>
                    <li>Креольский массаж в Spa;</li>
                    <li>Креольский массаж в коррекции фигуры;</li>
                    <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
                    <li>Разбор вопросов с преподавателем;</li>
                    <li>Проверка усвоенного материала.</li>
                  </ul>
                  <p><strong>Практическая часть:</strong></p>
                  <ul>
                    <li>Приёмы в креольском массаже;</li>
                    <li>Мастер-класс;</li>
                    <li>Изучение техники выполнения;</li>
                    <li>Отработка практических навыков.</li>
                  </ul>
                  <p><strong>Продолжительность:</strong> 72 ак.часа</p>
                  <p><strong>Заочно-дистанционно 10000₽</strong></p>
                  <p><strong>Очно в группе 18000₽</strong></p>
                  <p><strong>Очно-индивидуально 37000₽</strong></p>
                </>
              ) },
              { title: 'Курсы основных видов массажа',
              content: (
                <>
                  <p><strong>Программа курса:</strong></p>
                  <p><strong>Теоретическая часть:</strong></p>
                  <ul>
                    <li>Введение в анатомию (органы и системы человека: кожа, мышцы, скелет, лимфатическая и кровеносная системы, нервная система, суставы и связки, органы брюшной полости);</li>
                    <li>влияние массажа на органы и системы человека;</li>
                    <li>требования к массажисту и массируемому;</li>
                    <li>требования к помещению;</li>
                    <li>уход за руками;</li>
                    <li>профилактика профзаболеваний массажиста;</li>
                    <li>юридические аспекты профессии массажиста;</li>
                    <li>рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
                    <li>разбор вопросов с преподавателем;</li>
                    <li>проверка усвоенного материала.</li>
                  </ul>
                  <p><strong>Практическая часть:</strong></p>
                  <ul>
                    <li>Приемы в классическом(лицо и тело), лимфодренажном, антицеллюлитном, спортивном;</li>
                    <li>изучение техник выполнения;</li>
                    <li>техники энергосбережения;</li>
                    <li>Отработка практических навыков.</li>
                  </ul>
                  <p><strong>Продолжительность:</strong> 144 ак.часа</p>
                  <p><strong>Заочно-дистанционно 18000₽</strong></p>
                  <p><strong>Очно в группе 39000₽</strong></p>
                  <p><strong>Очно-индивидуально 69000₽</strong></p>
                </>
              )},
                { title: 'Курсы массажа в коррекции фигуры',
                content: (
                    <>
                      <p><strong>Программа курса:</strong></p>
                      <p><strong>Теоретическая часть:</strong></p>
                      <ul>
                        <li>введение в анатомические и физиологические особенности;</li>
                        <li>влияние массажа на локальные зоны, кожу и подкожно-жировую клетчатку, органы и системы человека;</li>
                        <li>показания и противопоказания;</li>
                        <li>требования к массажисту и массируемому;</li>
                        <li>требования к помещению;</li>
                        <li>обзор косметических средств для коррекции фигуры;</li>
                        <li>формирование программы коррекции фигуры по показаниям;</li>
                        <li>рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
                          <li>разбор вопросов с преподавателем;;</li>
                          <li>проверка усвоенного материала.</li>
                      </ul>
                      <p><strong>Практическая часть:</strong></p>
                      <ul>
                        <li>мастер-класс;</li>
                        <li>приёмы ручных техник:лимфодренажного, антицеллюлитного, вакуумно-баночного, медового;</li>
                        <li>приёмы аппаратных техник:вакуумного,вакуумно-роликового,кавитации,rf-лифтинга,миостимуляции;</li>
                        <li>техники энергосбережения;</li>
                        <li>изучение техник выполнения;</li>
                        <li>Отработка практических навыков.</li>
                      </ul>
                      <p><strong>Продолжительность:</strong> 72 ак.часа</p>
                      <p><strong>Заочно-дистанционно 10000₽</strong></p>
                      <p><strong>Очно в группе 18000₽</strong></p>
                      <p><strong>Очно-индивидуально 39000₽</strong></p>
                    </>
                  ) },
                  { title: 'Курсы лимфодренажного массажа',
                  content: (
                    <>
                      <p><strong>Программа курса:</strong></p>
                      <p><strong>Теоретическая часть:</strong></p>
                      <ul>
                        <li>введение в анатомические и физиологические особенности;</li>
                        <li>влияние массажа на локальные зоны, кожу и подкожно-жировую клетчатку, органы и системы человека;</li>
                        <li>требования к массажисту и массируемому;</li>
                        <li>рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
                        <li>разбор вопросов с преподавателем;</li>
                        <li>проверка усвоенного материала.</li>
                      </ul>
                      <p><strong>Практическая часть:</strong></p>
                      <ul>
                        <li>приёмы в лимфодренажном массаже;</li>
                        <li>изучение техники выполнения;</li>
                        <li>Отработка практических навыков.</li>
                      </ul>
                      <p><strong>Продолжительность:</strong> 18 ак.часа</p>
                      <p><strong>Заочно-дистанционно 5000₽</strong></p>
                      <p><strong>Очно в группе 9000₽</strong></p>
                      <p><strong>Очно-индивидуально 18000₽</strong></p>
                    </>
                  ) },
                    { title: 'Курсы Lpg-массажа',
                    content: (
                        <>
                          <p><strong>Программа курса:</strong></p>
                          <p><strong>Теоретическая часть:</strong></p>
                          <ul>
                            <li>введение в анатомические и физиологические особенности;</li>
                            <li>влияние массажа на локальные зоны, кожу и подкожно-жировую клетчатку, органы и системы человека;</li>
                            <li>показания и противопоказания;</li>
                            <li>последовательность действий при проведении LPG масажа;</li>
                            <li>требования к массажисту и массируемому;</li>
                            <li>требования к помещению;</li>
                            <li>рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
                            <li>разбор вопросов с преподавателем;</li>
                              <li>проверка усвоенного материала.</li>
                          </ul>
                          <p><strong>Практическая часть:</strong></p>
                          <ul>
                            <li>Приемы проведения LPG массажа;</li>
                            <li>изучение техники выполнения;</li>
                            <li>Отработка практических навыков.</li>
                          </ul>
                          <p><strong>Продолжительность:</strong> 15 ак.часа</p>
                          <p><strong>Заочно-дистанционно 5000₽</strong></p>
                          <p><strong>Очно в группе 8000₽</strong></p>
                          <p><strong>Очно-индивидуально 17000₽</strong></p>
                        </>
                      ) },
                      { title: 'Курсы спротивного массажа',
                      content: (
                        <>
                          <p><strong>Программа курса:</strong></p>
                          <p><strong>Теоретическая часть:</strong></p>
                          <ul>
                            <li>мастер-класс;</li>
                            <li>требования к помещению;</li>
                            <li>требования к массажисту и массируемому;</li>
                            <li>введение в спортивный массаж;</li>
                            <li>методики проведения различных видов;</li>
                            <li>рактеристика особенностей;</li>
                            <li>обзор используемых активных средств для наружнего применения;</li>
                            <li>разбор вопросов с преподавателем;</li>
                              <li>проверка усвоенного материала.</li>
                          </ul>
                          <p><strong>Практическая часть:</strong></p>
                          <ul>
                            <li>Приемы в спортивном массаже;</li>
                            <li>изучение техники выполнения;</li>
                            <li>подбор необходимого воздействия;</li>
                            <li>техники энергосбережения;</li>
                            <li>практики для использования в домашних условиях;</li>
                            <li>Отработка практических навыков.</li>
                          </ul>
                          <p><strong>Продолжительность:</strong> 18 ак.часа</p>
                          <p><strong>Заочно-дистанционно 5000₽</strong></p>
                          <p><strong>Очно в группе 9000₽</strong></p>
                          <p><strong>Очно-индивидуально 18000₽</strong></p>
                        </>
                      ) }
  ];
  
  const Accordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsOpen(!isOpen)}>
          <h3>{title}</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <p>{content}</p>
            <a href='' className="zapisatsya">Записаться</a>
          </div>
        )}
      </div>
    );
  };


  // В этой переменной вы можете заменить фото для блока с сертифакатами 
  const reviews = [
    {
        image: "./svidetelstvo2.jpg"
    },
    {
        image: "./svidetelstvo.jpg" 
        
    },
    {
        image: "./sertifikat0.jpg" 
    },
    // Добавьте другие отзывы сюда
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            ›
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            ‹
        </div>
    );
};
const massage = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Обучение массажу</h1>

                <h2>
                Каждый специалист в области массажа обязан владеть специальными навыками и даже приёмами мануальной терапии. Наша школа массажа дает возможность пройти углубленный курс по обучению массажу, как в режиме онлайн, так и очно. Медицинский массаж - это действенная и эффективная оздоровительная методика для поддержания здоровья, молодости и стремительной реабилитации после жизненных недугов. Обучающий курс помогает создавать новые кадры для работы в сфере оздоровительной медицины и реабилитации, а для проведения профессиональных сеансов нужны грамотно обученные помощники, как с профильным медицинским образованием, так и с естественным желанием помогать и осваивать новые технологии без медицинского образования.
                <br />

                Пройдя обучение курсам массажа, обучающийся получает специальный сертификат, или диплом Государственного образца и бесценные навыки самым востребованным техникам и методам, позволяющих помогать людям в всевозможных сферах, проводить детский сеанс или курс для взрослых. Наши преподаватели - это люди с большим опытом, которые научат помогать клиентам разностороннего профиля: Реабилитация после травм. Поддержание здоровья. Оздоровительные сеансы. Массаж лица - молодость кожи. Процедуры для укрепления костно-мышечного скелета взрослым и детям.
                <br />

                Также, на занятиях вы овладеваете техниками массажа разного направления для отдельных частей тела. Стать массажистом может каждый, кто пожелает, а для этого следует пройти специализированное обучение под руководством наших мастеров. В процессе курса вы получаете не только теоретические знания, но и овладеваете практическими методиками относительно стандартных и инновационных тактик, с отработкой приемов и постановкой руки на моделях. Столь грамотный и углубленный подход открывает возможность перед учениками нашей студии овладеть как классическими, так и эстетическими приемами.
                Теоретические и практические занятия проводит опытный преподаватель, который исключительным образом находит индивидуальный подход к каждому, рассказывает все тонкости данной профессии по общему или отдельному узкому профилю, озвучивает секреты мануальных техник. Все это позволяет не только получить диплом по окончанию обучения, но и вырастить из ученика мастера высочайшего класса, а практикующим массажистам повысить уровень квалификации. По окончанию курса выдаются документы, дающие возможность законно предоставлять услуги в частной сфере или работать с официальным оформлением. После обучения курсу массажа в нашей студии, полученная вами новая профессия будет вызывать только положительные эмоции!
                </h2>
            </main>


            <div className="accord-block">
                {courses.map((course, index) => (
                    <Accordion key={index} title={course.title} content={course.content} />
                ))}
            </div>

            {products.map((product) => (
                <div key={product.id} className="btn-block">

                    <button className="btn-block" style={{border: 0, cursor: "pointer"}}  onClick={() => handleAddToCart(product)}>Записаться →</button>
                </div>
            ))}
            <div className="review-slider" style={{height: 500}}>
              <h1>Отзывы об услуге</h1>
              <Slider {...settings}>
                  {reviews.map((review, index) => (
                      <div key={index} className="review">
                          <img className='img-sl' src={review.image} alt={`Отзыв ${index + 1}`} /> {/* Добавьте элемент img */}
                      </div>
                  ))}
              </Slider>
          </div>
        </div>
    );
}

export default massage