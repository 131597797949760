import React, { useState } from 'react';
import Slider from 'react-slick';
import './massage.css'
const products = [
    // { id: '#', title: "Тату надпись", price: 1500, desc: '', city: '' , imgClass: 'card__img-5 obuc-cos916'},
]
const courses = [
    {
      title: 'Косметик-Эстетист в Волгограде',
      content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
              <li>Введение в косметологию. Оборудование косметологического кабинета. Санитарные нормативы. Асептика, антисептика, методы дезинфекции;</li>
            <li>Гистология, анатомия, физиология, биохимия кожи, микрофлора кожи;</li>
            <li>Основы дерматологии. Строение кожи. Морфологические элементы кожи. Кожные заболевания. Типы и особенности функционального состояния кожи. Основные типы кожи: сухая, жирная, комбинированная, нормальная;</li>
            <li>Косметическая химия. Классификация готовых косметических средств, характеристика сырья. Алгоритм чтения рецептуры косметических препаратов;</li>
            <li>Виды диагностики. Алгоритм определения типа кожи.(нормальная, сухая, жирная, комбинированная);</li>
            <li>Классификация косметических средств по типам кожи, проблемам. Особенности рецептур;</li>
            <li>Подготовка кожи перед началом косметических процедур. Техника и средства демакияжа. Средства для пилинга и проведение процедуры глубокого очищения;</li>
            <li>Основные этапы косметического ухода. Очищение. Увлажнение. Питание. Защита. Особенности сезонного ухода. Профессиональная и домашняя косметика;</li>
              <li>Глубокое очищение. Интракорниальный пилинг: средства;</li>
              <li>Основные этапы повседневного ухода: очищение, увлажнения, питание, защита. Особенности сезонного ухода. Домашняя косметика;</li>
              <li>Нормальная кожа, сухая кожа особенности ухода;</li>
              <li>Жирная и комбинированная кожа, особенности ухода;</li>
              <li>Основы анатомии головы и шеи;</li>
              <li>Виды косметического массажа: косметический классический и пластический массаж;</li>
              <li>Маски и компрессы. Виды, средства;</li>
              <li>Методы коррекции фигуры. Виды обертывания. Косметические средства в процедурах обертывания;</li>
              <li>Механическая чистка: виды, алгоритмы построения, алгоритм работы с жирной кожей;</li>
              <li>Методы удаления волос в косметологии. Эпиляция. Депиляция. Обзор материалов для депиляции: горячий и теплый воск, фитосмола, сахарная паста. Уход за кожей после депиляции. Подбор средств по типам кожи;</li>
              <li>рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
              <li>разбор вопросов с преподавателем;</li>
              <li>проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Демакияж, глубокое отчищение, маски,пилинги и компрессы. Техника исполнения;</li>
            <li>Косметический массаж, по Жаке, по Абахадзе лица,шеи и декольте. Техника исполнения;</li>
            <li>Массаж лица и шеи. Техника исполнения;</li>
            <li>Механическая чистка,УЗИ чистка,комбинированная, фонофорез. Техника исполнения;</li>
            <li>Техника наложения парафиновой маски (горячее моделирование лица). Техника наложения альгинатной маски (холодное моделирование лица);</li>
            <li>Обертывания тела и наложение масок для лица. Техника исполнения;</li>
            <li>Эпиляция. Депиляция. Техники выполнения;</li>
              <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 320 ак.часа</p>
          <p><strong>Заочно-дистанционно 15000₽</strong></p>
          <p><strong>Очно в группе 37500₽</strong></p>
          <p><strong>Очно-индивидуально 75000₽</strong></p>
        </>
      )
    },
    { title: 'Сестринское дело в косметологии',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Нормативно-правовое регулирование организации медицинской помощи по профилю косметология;</li>
            <li>Введение в косметологию. Санитарные нормативы. Асептика и антисептика. Методы дезинфекции. Стерилизация и стерилизаторы;</li>
            <li>Гистология, анатомия, физиология, биохимия кожи, микрофлора кожи;</li>
            <li>Диагностика эстетических дефектов и патологических состояний кожи;</li>
            <li>Неинфекционные заболевания кожи. Инфекционные заболевания кожи;</li>
            <li>Локальная гипертрофия и гипотрофия жировой клетчатки;</li>
            <li>Эстетические недостатки. Понятие. Классификации. Клинические проявления;</li>
            <li>Физиотерапевтические методы, используемые в косметологии для коррекции. Характеристика физических факторов. Методы физиотерапевтического воздействия;</li>
              <li>Современные концепции ухода за кожей;</li>
              <li>Влияние эстетических недостатков на качество жизни пациентов;</li>
              <li>Комплексный подход при составлении программ коррекции эстетических недостатков;</li>
              <li>Сосудистые заболевания кожи и методы их коррекции;</li>
              <li>Чистка лица. Виды. Показания,противопоказания. Техника выполнения;</li>
              <li>Химические пилинги. Классификация. Показания, противопоказания, возможные осложнения. Техникавыполнения. Предпилинговая подготовка, постпилинговый уход;</li>
              <li>Физиотерапевтические методы в косметологии. Биологические аспекты воздействия физических факторов на ткани;</li>
              <li>Криотерапия. Показания, противопоказания. Методики;</li>
              <li>Термотерапия. Показания, противопоказания. Методики;</li>
              <li>Озонотерапия. Показания, противопоказания. Методики;</li>
              <li>Методы применения постоянного тока (гальванизация, лекарственный электрофорез, дезинкрустация);</li>
              <li>Импульсные и моделированные токи(микротоковая терапия,миостимуляция, лимфодренаж,электролиполиз, дарсонваль);</li>
              <li>Факторы механической природы (вакуумный массаж, чистка);</li>
              <li>Световые методики (УФО, солярий,селективная и неселективная фоторепия);</li>
              <li>Лазеры. Области применения в косметологии;</li>
              <li>Эпиляция. Депиляция. Методы (ваксинг,шугаринг, электроэпиляция, фотоэпиляция, лазерная);</li>
              <li>Безинъекционные методы в косметологии. Показания,противопоказания, возможные осложнения. Безинъекционная мезотерапия, безинъекционная биоревитализация;</li>
              <li>Обзор инъекционных методов коррекции эстетических недостатков(мезотерапия, биоревитализация,ботулинотерапия, контурное и объемное моделирование,тредлифтинг);</li>
              <li>Медицинская помощь при экстренныхи неотложных состояниях;</li>
              <li>Виды косметического массажа: косметический классический и пластический массаж;</li>
              <li>Рекомендации по работе и общению с клиентом от практикующих косметологов;</li>
              <li>Разбор вопросов с преподавателем;</li>
              <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Техника наложения парафиновой маски (горячее моделирование лица). Техника наложения альгинатной маски (холодное моделирование лица);</li>
            <li>Косметический классический и пластический массаж. Техника исполнения;</li>
            <li>Химические пилинги. Техника выполнения;</li>
            <li>Механическая чистка лица. УЗИ-чистка. Техника исполнения;</li>
            <li>Аппаратные методики для "Сестринское дело в косметологии". Техники выполнения;</li>
            <li>Инвазивные методы в "Сестринское дело в косметологии". Техники выполнения;</li>
            <li>Депиляция. Шугаринг. Техники выполнения;</li>
              <li>Обертывания тела и наложение масок для лица. Техника исполнения;</li>
              <li>Отработка практического навыка.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 480 ак.часа</p>
          <p><strong>Очно в группе 57000₽</strong></p>
          <p><strong>Очно-индивидуально 89000₽</strong></p>
        </>
      ) },
    { title: 'Курс по пирсингу',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Вводная часть. Основы асептики и антисептики. Оборудование, инструменты, используемые в процедуре;</li>
            <li>Материалы для украшения: Хирургическая сталь, титан, золото и платина, серебро, пластик. Анестезия. Противошоковая терапия;</li>
            <li>Противопоказания. Осложнения;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Техника выполнения процедуры, в зависимости от локализации прокола;;</li>
            <li>Прокол мочки уха пистолетом. Прокол уха иглой-катетером. Прокол пупка. Прокол языка. Прокол крыла носа. Прокол носовой перегородки. Прокол брови. Прокол соска. Прокол нижней губы. Техника исполнения;;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 15 ак.часа</p>
          <p><strong>Очно в группе 13000₽</strong></p>
          <p><strong>Очно-индивидуально 19000₽</strong></p>
        </>
      ) },
    { title: 'Курс Аппаратные методы в косметологии',
        content: (
            <>
                <p><strong>Программа курса:</strong></p>
                <p><strong>Теоретическая часть:</strong></p>
                <ul>
                    <li>Техника выполнения Ультразвуковой чистки для глубокого очищения кожи, удаление ороговевших чешуек и отбеливание кожи, лечения проблемной кожи, сокращения пор, разглаживания рубцов и растяжек;</li>
                    <li>Броссаж – способ механической очистки кожи от различных загрязнений и ороговевших частичек;</li>
                    <li>Техника выполнения Кавитации и RF-lifting</li>
                    <li>Техника выполнения Гидропилинг;</li>
                    <li>Техника выполнения Вакуумно-роликого массажа</li>
                    <li>Техника выполнения Алмазной (микрокристаллическая) микродермабразии - современный метод шлифовки кожи, вид косметического механического пилинга;</li>
                    <li>Техника выполнения Гальванизации – воздействие на организм постоянным электрическим током для введения в нее питательных веществ удаляя загрязнения и ионы тяжелых металлов;</li>
                    <li>Принципы работы аппаратов,используемых в косметологии. Дарсонвализация - воздействие переменным импульсивным током;</li>
                    <li>рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
                    <li>разбор вопросов с преподавателем;</li>
                    <li>проверка усвоенного материала.</li>
                </ul>
                <p><strong>Практическая часть:</strong></p>
                <ul>
                    <li>Броссаж. Техника выполнения;</li>
                    <li>Алмазная микродермабразия.Техника выполнения;</li>
                    <li>ультразвуковая чистка. Техника исполнения;</li>
                    <li>Гальванизация и дарсонвализация. Техника исполнения;</li>
                    <li>Вакуумно-роликого массажа. Техника выполнения;</li>
                    <li>Кавитации и RF-lifting. Техника выполнения;</li>
                    <li>Отработка практических навыков.</li>
                </ul>
                <p><strong>Продолжительность:</strong> 32 ак.часа</p>
                <p><strong>Заочно-дистанционно 13000₽</strong></p>
                <p><strong>Очно в группе 19000₽</strong></p>
                <p><strong>Очно-индивидуально 39000₽</strong></p>
            </>
        ) },
    { title: 'Курсы Инъекционные методы в косметологии',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Введение в мезотерапию. История мезотерапии, теории механизмов действия. Механизм действия, показания к использованию мезотерапии в эстетической медицине, противопоказания. Вопросы асептики и безопасности проведения процедур;</li>
            <li>Побочные эффекты и осложнения, связанные с технологией выполнения инъекционной мезотерапии. Аллергические реакции и их нейтрализация. Подготовка пациента. Медицинская карта пациента. Информированное согласие. Памятка для пациента, рекомендации после процедуры;</li>
            <li>Характеристика и базовые принципы выбора мезо-препаратов;</li>
            <li>Материалы для мезотерапии;</li>
            <li>Фармакология препаратов, используемых в мезотерапии: препараты для увлажнения, лифтинга, коррекции морщин; сосудистые препараты;</li>
            <li>Правила составления мезотерапевтических коктейлей. "Магистральный коктейль". Рецептура мезококтейлей для коррекции возрастных изменений лица, шеи, декольте;</li>
            <li>Проведение процедуры. Обезболивание в процедуре, виды анестезирующих средств. Основные ошибки в процедуре мезотерапии;</li>
            <li>Физиология и морфология старения (биологического и фотоиндуцированного). Роль и место, которое занимает системная биоревитализация в комплексных Anti-age программах;</li>
              <li>Принципы классификации современных биоревитализантов, целесообразность создания комбинированных препаратов гиалуроновой кислоты;</li>
              <li>Обзор современных биоревитализантов;</li>
              <li>Биоревитализация - как подготовительный этап перед проведением инвазивных и физиотерапевтических омолаживающих процедур;</li>
              <li>Принципы составления индивидуальных программ биоревитализации;</li>
              <li>Выбор биоревитализанта в зависимости от выраженности возрастных изменений;</li>
              <li>Мезотерапевтические техники проведения биоревитализации: микропапулы, микроблюсы, скарификация;</li>
              <li>Схемы проведения биревитализации лица, шеи, декольте, губ, мочек ушей, кистей рук;</li>
              <li>Сочетание биревитализации с другими методиками (пилинги, шлифовки, ботулотоксин);</li>
              <li>Введение в работу с ботулотоксином. История ботулотоксина, механизмы действия. Показания к использованию, противопоказания.</li>
              <li>Побочные эффекты и осложнения, связанные с технологией выполнения инъекций ботулотоксина. Рекомендации после процедуры;</li>
              <li>Обзор современных ботулотоксинов;</li>
              <li>Проведение процедуры. Основные ошибки в процедуре;</li>
              <li>Введение в контурную пластику. Механизмы действия. Показания к применению, противопоказания. Вопросы асептики и безопасности проведения процедур;</li>
              <li>Побочные эффекты и осложнения, связанные с технологией выполнения контурной пластики. Возможные реакции и их нейтрализация. Подготовка пациента, рекомендации после процедуры;</li>
              <li>Обзор современных средств для контурной пластики;</li>
              <li>Проведение процедуры. Обезболивание в процедуре, виды анестезирующих средств. Основные ошибки в контурной пластике;</li>
              <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
              <li>Разбор вопросов с преподавателем;</li>
              <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Мезотерапевтические техники в коррекции эстетических проблем кожи лица, шеи, декольте; в лечении рубцовых изменений и стрий. Техника исполнения;</li>
            <li>Протоколы и техника проведения процедуры. Мезороллеры и мануальная техника.</li>
            <li>Мезотерапевтические техники исполнения биоревитализации: микропапулы, микроблюсы, скарификация;</li>
            <li>Работа с ботулотоксином. Техники проведения в коррекции эстетических проблем;</li>
            <li>Протоколы и техники в коррекции эстетических проблем. Техники проведения процедур в контурной пластике;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 72 ак.часа</p>
          <p><strong>Очно в группе 35000₽</strong></p>
          <p><strong>Очно-индивидуально 79000₽</strong></p>
        </>
      ) },
    { title: 'Курс Мезотерапии',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Понятие процедуры алмазный пилинг (микродермабразия);</li>
            <li>Косметические препараты, применяемые до и вовремя процедуры алмазный пилинг(микродермабразия);</li>
            <li>Показания, противопоказания к процедуре; алмазный пилинг (микродермабразия);</li>
            <li>рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
            <li>разбор вопросов с преподавателем;</li>
            <li>проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Изучение техники выполнения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 12 ак.часа</p>
          <p><strong>Заочно-дистанционно 5000₽</strong></p>
          <p><strong>Очно в группе 9000₽</strong></p>
          <p><strong>Очно-индивидуально 15000₽</strong></p>
        </>
      ) },
    { title: 'Курс химические пилинги',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Химический пилинг: показания, противопоказания. Преимущества и недостатки различных кислот химического пилинга;</li>
            <li>Характеристика различных химических пилингов. Подготовка к процедуре пилинга. Уход за кожей после процедуры;</li>
            <li>Клиническая характеристика нежелательных явлений после пилинга;</li>
            <li>Профилактика и лечение нежелательных явлений. Памятка для косметолога;</li>
            <li>Диагностические таблицы по выбору метода химического пилинга;</li>
            <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
            <li>Разбор вопросов с преподавателем;</li>
            <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Поверхностные и срединные пилинги. Техника исполнения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 32 ак.часа</p>
          <p><strong>Заочно-дистанционно 7000₽</strong></p>
          <p><strong>Очно в группе 15000₽</strong></p>
          <p><strong>Очно-индивидуально 29000₽</strong></p>
        </>
      ) },
    { title: 'Курс Восковая депиляция.Ваксинг. Шугаринг',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>История возникновения депиляции. Требования к кабинету. Виды депиляции. Аппараты, инструменты, вещества, применяемые для депиляции. Фаза роста волоса и его строение. Характеристика восков для депиляции;</li>
            <li>Противопоказания для проведения процедуры. Уход до- и после депиляции. Характеристика средств;</li>
            <li>Специфика роста волос в области бикини. Классическое бикини, глубокое бикини;</li>
            <li>Типы волос, анатомические и физиологические особенности. Показания и противопоказания к применению шугаринга. Преимущества метода;</li>
            <li>Особенности мануальной и бандажной техник шугаринга. Разновидности паст для сахарной депиляции, их составы и правила применения. Сравнительный анализ сахарных паст разных производителей;</li>
            <li>Описание теории правил процедур: подготовка к эпиляции; методики подготовки пасты; применяемые техники эпиляции; после применения пасты;</li>
            <li>Методика работы с сахарной пастой;</li>
            <li>Рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
              <li>Разбор вопросов с преподавателем;</li>
              <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Восковая депиляция по зонам техника исполнения;</li>
            <li>Восковая депиляция. Техника работы с теплыми кассетными;</li>
            <li>Классическое бикини, глубокое бикини. Техника исполнения;</li>
            <li>Мануальная и бондажная техника по зонам демонстрация;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 32 ак.часа</p>
          <p><strong>Заочно-дистанционно 5000₽</strong></p>
          <p><strong>Очно в группе 15000₽</strong></p>
          <p><strong>Очно-индивидуально 29000₽</strong></p>
        </>
      ) },
    { title: 'Курсы тату(Профи)',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Изучение стилей — их специфики, особенностей и отличий;</li>
            <li>Санитарные требования к кабинету, организация рабочего места;</li>
            <li>Оборудование: как грамотно его подобрать и купить по разумной цене;</li>
            <li>Сборка и настройка тату машинок;</li>
            <li>Расходные материалы;</li>
            <li>Противопоказания к татуировке;</li>
            <li>Уход и заживление;</li>
            <li>Рекомендации по работе и общению с ребенком от практикующих мастеров;</li>
              <li>Разбор вопросов с преподавателем;</li>
              <li>Проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Контур, тень, грейвош, градиент и особенности их выполнения;</li>
            <li>Обрисовка и перевод эскиза на кожу;</li>
            <li>изучение техник выполнения;</li>
            <li>Отработка практических навыков;</li>
            <li>Экзамен.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 35 ак.часа</p>
          <p><strong>Очно в группе 35500₽</strong></p>
          <p><strong>Очно-индивидуально 75500₽</strong></p>
        </>
      ) },
    { title: 'Курс Мини-тату',
        content: (
            <>
                <p><strong>Программа курса:</strong></p>
                <p><strong>Теоретическая часть:</strong></p>
                <ul>
                    <li>Виды оборудования, санитарные требования, стерилизация;</li>
                    <li>Организация рабочего места;</li>
                    <li>Сборка и настройка тату машинок;</li>
                    <li>показания противопоказания к тату;</li>
                    <li>Расходные материалы: иглы, носики и тд — как выбрать;</li>
                    <li>Техники и стили татуировок;</li>
                    <li>Требования к тату-мастеру;</li>
                    <li>Разбор вопросов с преподавателем;</li>
                    <li>Проверка усвоенного материала.</li>
                </ul>
                <p><strong>Практическая часть:</strong></p>
                <ul>
                    <li>Подготовка и перевод эскиза на кожу;</li>
                    <li>Правила заживления и ухода за татуировкой;</li>
                    <li>Изучение техники выполнения;</li>
                </ul>
                <p><strong>Продолжительность:</strong> 15 ак.часа</p>
                <p><strong>Очно в группе 15000₽</strong></p>
                <p><strong>Очно-индивидуально 25000₽</strong></p>
            </>
        ) },
    { title: 'Обучение Перманентному макияжу',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>История и суть перманентного макияжа;</li>
            <li>показания противопоказания;</li>
            <li>показания противопоказания;</li>
            <li>Обустройство рабочего места;</li>
            <li>асептика и антисептика;</li>
            <li>Строение и типы кожи;</li>
            <li>Колористика и пигменты;</li>
            <li>Анатомические вопросы (строение черепа, положение мышц, кости);</li>
              <li>Правила и приемы в визаже, эскиз;</li>
              <li>Подготовка кожи к процедуре, регенерация кожи, обработка после процедуры;</li>
              <li>Анестезия виды и противопоказания;</li>
              <li>Ошибки и методы их исправления (разбираем ошибки учеников и других мастеров, учимся их исправлять);</li>
              <li>Брови (виды нанесения перманентного макияжа;)</li>
              <li>Глаза. Классическая стрелка, межресничка. Губы. Контур растушевка;</li>
              <li>разбор вопросов с преподавателем;</li>
              <li>проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Приёмы нанесения перманентного макияжа;</li>
            <li>изучение техники выполнения;</li>
            <li>Отработка практических навыков.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 35 ак.часа</p>
          <p><strong>Очно в группе 35500₽</strong></p>
          <p><strong>Очно-индивидуально 75500₽</strong></p>
        </>
      ) },
    { title: 'Курс наращивания ресниц',
        content: (
            <>
                <p><strong>Программа курса:</strong></p>
                <p><strong>Теоретическая часть:</strong></p>
                <ul>
                    <li>Знакомство с материалами и инструментами. Противопоказания к процедуре. Подготовка к процедуре наращивания пучков;;</li>
                    <li>Коррекция ресниц. Правила ухода за наращенными ресницами. Снятие ресниц. Уход после снятия ресниц.</li>
                    <li>рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
                    <li>разбор вопросов с преподавателем;</li>
                    <li>проверка усвоенного материала.</li>
                </ul>
                <p><strong>Практическая часть:</strong></p>
                <ul>
                    <li>Техники наращивания пучков ресниц;</li>
                    <li>Отработка практического навыка.</li>
                </ul>
                <p><strong>Продолжительность:</strong> 18 ак.часа</p>
                <p><strong>Заочно-дистанционно 5000₽</strong></p>
                <p><strong>Очно в группе 13000₽</strong></p>
                <p><strong>Очно-индивидуально 23000₽</strong></p>
            </>
        ) },
      { title: 'Курс мастера-бровиста',
      content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Функции брови, цель корпрекции. Необходимые инструменты, профессиональный выбор. Методы коррекции бровей;</li>
            <li>Подбор правильной формы брови. Основные проблемные зоны. Формула расчета формы. Распространенные ошибки в коррекции брови;</li>
            <li>Первичная и вторичная коррекция и окрашивание бровей;</li>
            <li>Противопоказания к окрашиванию бровей и ресниц. Косметическое оформление брови;</li>
            <li>Виды хны для окрашивания бровей. Преимущества окрашивания бровей хной. Уход за бровями после окрашивания хной;</li>
            <li>Виды хны для окрашивания бровей. Преимущества окрашивания бровей хной. Уход за бровями после окрашивания хной;</li>
            <li>рекомендации по работе и общению с клиентом от практикующих мастеров;</li>
            <li>разбор вопросов с преподавателем;</li>
              <li>проверка усвоенного материала.</li>
          </ul>
          <p><strong>Практическая часть:</strong></p>
          <ul>
            <li>Техника выполнения коррекции и окрашивания бровей;.</li>
            <li>Отработка практического навыка работы.</li>
          </ul>
          <p><strong>Продолжительность:</strong> 25 ак.часа</p>
          <p><strong>Заочно-дистанционно 5000₽</strong></p>
          <p><strong>Очно в группе 15500₽</strong></p>
          <p><strong>Очно-индивидуально 28000₽</strong></p>
        </>
      ) },

  ];
  
  const Accordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsOpen(!isOpen)}>
          <h3>{title}</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <p>{content}</p>
            <a href='' className="zapisatsya">Записаться</a>
          </div>
        )}
      </div>
    );
  };

  const reviews = [
    {
        image: "./svidetelstvo2.jpg"
    },
    {
        image: "./svidetelstvo.jpg" 
        
    },
    {
        image: "./sertifikat0.jpg" 
    },
    // Добавьте другие отзывы сюда
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            ›
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            ‹
        </div>
    );
};
const obucc = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Обучение на косметолога в {titlee}</h1>

                <h2>
                Лучшие курсы косметологии, дающие не только базовые знания, но и первоначальные практические навыки, найти довольно сложно. Наш институт предлагает профессиональное обучение для будущих косметологов профильного медицинского и без медицинского образования по самым прогрессивным программам и современным методикам. У нас каждый желающий осваивает профессию косметолога с нуля, под четким контролем наших опытных профессионалов своего дела.
                <br />

                Обучение в нашем институте, включает в себя углублённую подготовку по всевозможным направлениям, среди которых лидирующее место занимают курсы инъекционной косметологии, эстетическая, контурная пластика, аппаратная, а также медицинский массаж, уход и пилинг. Во время занятий ученики осваивают не только теоретическую базу, получает сертификат, но и в сопровождении опытных косметологов отрабатывают практическое мастерство с постановкой руки на моделях.
                <br />

                Обучение курсам косметологии подойдёт для всех, кто хочет освоить новые техники или закрепить уже полученные навыки. У нас работают грамотные преподаватели, которые помогут освоить виды косметологических процедур и научат понимать их механизм действий. Теперь каждый желающий может пройти обучение очно только под пристальным руководством наших специалистов и в дальнейшем легко работать со стандартными и нестандартными схемами и методиками. Также доступны курсы онлайн в медицинской косметологии, которые помогают будущим творцам красоты полноценно подходить к коррекционным процедурам.
                <br />
                А самое главное, во время обучения молодые специалисты научатся избегать и правильно решать проблему с возможными осложнениями, после процедуры. Косметология в наше время — это совокупность инновационных уходовых технологий за кожей лица и всего тела, инъекционная техника, аппаратные методики, а также, широко применяемые современные принципы глубинных пилингов, мезотерапии, контурной пластики и других салонных процедур. Косметолог - эстетист - это динамически развивающаяся профессия, которая требует неустанного совершенствования.
                <br />
                Наши курсы готовят профессиональных, грамотных специалистов для салонов высоких требований и стандартов. Полученные теоретические знания на курсах косметологии подкрепляются практическими навыками и отрабатываются на клиентах-добровольцах. Только у нас вы сможете быстро и основательно отточить приобретённые умения, получить диплом Государственного образца и благополучно построить карьеру в этой прогрессирующей сфере красоты! Полученные документы по окончанию курса открывают возможность официального трудоустройства, а также предоставления услуг в частной практике на законном основании.
                </h2>
            </main>


            <div className="accord-block">
                {courses.map((course, index) => (
                    <Accordion key={index} title={course.title} content={course.content} />
                ))}
            </div>

            {products.map((product) => (
                <div key={product.id} className="btn-block">

                    <button className="btn-block" style={{border: 0, cursor: "pointer"}}  onClick={() => handleAddToCart(product)}>Записаться →</button>
                </div>
            ))}
            <div className="review-slider" style={{height: 500}}>
              <h1>Отзывы об услуге</h1>
              <Slider {...settings}>
                  {reviews.map((review, index) => (
                      <div key={index} className="review">
                          <img className='img-sl' src={review.image} alt={`Отзыв ${index + 1}`} /> {/* Добавьте элемент img */}
                      </div>
                  ))}
              </Slider>
          </div>
        </div>
    );
}

export default obucc