import React from 'react';
import Slider from 'react-slick';
import './massage.css'

const products = [
    { id: 154, title: "Массаж для лица",spis1:'Лимфодренажный массаж лица ',spis2:'Классический массаж лица',spis3:'Косметический массаж лица ',spis4:'Пластический(миоскульптурный) массаж лица',spis5:'Буккальный массаж лица',spis6:'Массаж лица по Жаке ',spis7:'Точечный массаж лица',spis8:'Японский массаж лица',spis9:'Стоунтерапия для лица',spis10:'Ультразвуковой массаж лица',spis11:'Вакуумный массаж лица', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'] , desc: '', city: '' , imgClass: 'card__img-3 card__img-search-154'},


]




const reviews = [
    {
        name: "Ксения",
        date: "17.05.2023",
        rating: "отлично",
        advantages: "Расслабляет",
        disadvantages: "Хочется еще",
        comment: "Решила первый раз пойти на масляный массаж. Зря не попробовала это чудо раньше...",
        adminResponse: "Спасибо за Ваш отзыв"
    },
    {
        name: "Ксения2",
        date: "17.05.2023",
        rating: "отлично",
        advantages: "Расслабляет",
        disadvantages: "Хочется еще",
        comment: "Решила первый раз пойти на масляный массаж. Зря не попробовала это чудо раньше...",
        adminResponse: "Спасибо за Ваш отзыв"
    },
    // Добавьте другие отзывы сюда
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            ›
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            ‹
        </div>
    );
};
const massageface = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart, handleAddToCartWithNotification}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Массаж для лица в {titlee}</h1>

                <h2>
                Массаж лица - это непревзойденное средство, которое помогает замедлить процессы старения. Косметическая процедура проводится среди пациентов любого пола, возраста и типом кожи.Делать ли массаж лица? - вопрос, который встречается все чаще. Ответ очевиден, так как с помощью этой процедуры усиливают микроциркуляцию в клетках, что в дальнейшем способствует разглаживанию морщин, улучшению цвета лица, обретению молодости и повышению мышечного тонуса.
                Также, мануальные воздействия помогают избавиться от отечного синдрома, уменьшить пигментацию, подчеркнуть овал лица.Техника массажа лица разнится для каждого клиента и должна подбираться отдельно в зависимости от поставленных задач и состояния кожных покровов. Пожалуй, нет результативнее процедуры, которая позволит замедлить увядания и старения кожи без дополнительных приемов лифтинга.
                Косметический массаж лица проводят для того, чтобы затормозить старение на клеточном уровне и ликвидировать уже проявившиеся кожные дефекты. Дипломированные массажисты нашего салона грамотно владеют мануальными техниками и применяют методы точечного воздействия, которые не только улучшают работу сосудов и сальных желез, но и восстанавливают психоэмоциональное равновесие.Лечебный и восстанавливающий массаж лица может быть нескольких видов:
                Классический массаж. Специальная мануальная техника для людей с начальными признаками старения. Пластический. Подходит для категории клиентов 30+, вариант для людей с жирной и комбинированной кожей. Надежный метод для профилактики возрастных изменений кожи лица. Лимфодренажный массаж лица направлен на лимфодренажную стимуляцию, повышения эластичности кожи и разглаживанию морщин. Буккальный массаж лица - мануальное воздействие по авторской методике с проработкой мышечного слоя щек, как с внешней, так и внутренней стороны.И это далеко не все разновидности.
                Медицинский массаж лица уже через несколько процедур помогает достичь желаемого стойкого эффекта, но следует понимать, что результат носит индивидуальный характер и зависит от особенностей кожного покрова и от регулярности, количества сеансов. Оправдать ожидания клиентов и обрести былую молодость помогут наши опытные специалисты салона. Помните, что лицо - это зеркало души, которое должно быть чистым, светлым, свежим!
                </h2>
            </main>

            <section className="massage-second-block">
                <h1>Наши услуги</h1>
                <div className="cards-m">
                    {products.map((product) => (
                        <div key={product.id} className="massage-card">
                            <div className={product.imgClass}></div>
                            <div style={{flex: 0.5}}>
                                <h1 className="card__title">{product.title}</h1>
                                <ul className="mas-u">
                                    {product.spis1 && <li>{product.spis1}</li>}
                                    {product.spis2 && <li>{product.spis2}</li>}
                                    {product.spis3 && <li>{product.spis3}</li>}
                                    {product.spis4 && <li>{product.spis4}</li>}
                                    {product.spis5 && <li>{product.spis5}</li>}
                                    {product.spis6 && <li>{product.spis6}</li>}
                                    {product.spis7 && <li>{product.spis7}</li>}
                                    {product.spis8 && <li>{product.spis8}</li>}
                                    {product.spis9 && <li>{product.spis9}</li>}
                                    {product.spis10 && <li>{product.spis10}</li>}
                                    {product.spis11 && <li>{product.spis11}</li>}
                                    {product.spis12 && <li>{product.spis12}</li>}
                                    {product.spis13 && <li>{product.spis13}</li>}
                                    {product.spis14 && <li>{product.spis14}</li>}
                                </ul>
                                <button className="card__btn" onClick={() => handleAddToCartWithNotification(product)}>Забронировать</button>
                            </div>
                        </div>
                    ))}
                </div>
            </section>

            <main className="middle-block">
                <div className="title-block-mas">
                    <h1>3 причины выбрать нас</h1>
                    <h2>Мы работаем каждый день, чтобы сделать вашу жизнь счастливее</h2>
                </div>
            <div className="hg">
                <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>

                <div>
                    <h5>Высокое качество</h5>
                    <h6>Наши специалисты постоянно повышают свою квалификацию и участвуют в международных конференциях.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>
                <div>
                    <h5>Чистота и комфорт</h5>
                    <h6>Для нас важно, чтобы клиенты чувствовали себя комфортно. Вот почему мы всегда следим за чистотой и атмосферой наших салонов.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>
                <div>
                    <h5>Индивидуальный подход</h5>
                    <h6>Наша компания работает по принципу индивидуального подхода к каждому клиенту. Мы уважаем ваши пожелания и предпочтения.</h6>
                </div>
            </div>
            </main>

            <div className="review-slider">
            <h1>Отзывы об услуге</h1>
            <Slider {...settings}>
                {reviews.map((review, index) => (
                    <div key={index} className="review">
                        <p><strong>{review.name} {review.date}</strong></p>
                        <p>{review.rating}</p>
                        <p><strong>Достоинства:</strong> {review.advantages}</p>
                        <p><strong>Недостатки:</strong> {review.disadvantages}</p>
                        <p><strong>Комментарий:</strong> {review.comment}</p>
                        <p><strong>Ответ администратора {review.date}</strong></p>
                        <p>{review.adminResponse}</p>
                    </div>
                ))}
            </Slider>
            </div>

            <section className='fo-block'>
                <h1>Специалисты</h1>
                <h2>Опытные профессионалы своего дела!</h2>

                <div className="spec">
                    <div className="spec-card">
                        <div className="spec-img-1"></div>

                        <div className="spec-title">
                        Массаж— поистине это полезно и предотвращает от болезней органов 
                        </div>
                    </div>


                    <div className="spec-card">
                        <div className="spec-img-2"></div>

                        <div className="spec-title">
                        Эффект массажа — естественная восстанавливающая сила организма, сила жизни 
                        </div>
                    </div>

                    <div className="spec-card">
                        <div className="spec-img-3"></div>

                        <div className="spec-title">
                        Массаж во врачевании во все времена играл огромную роль, и люди давно должны были поставить памятник рукам массажиста.
                        </div>
                    </div>

                    <div className="spec-card">
                        <div className="spec-img-4"></div>

                        <div className="spec-title">
                        Наше тело всё время говорит с нами. Если бы мы только нашли время послушать
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default massageface