import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import emailjs from 'emailjs-com';
import Footer from './components/footer/Footer';
import Header from './components/header/Header';
import HomePage from './components/main-page/HomePage';
import Massage from './components/pages/massage-page';
import MassageTwo from './components/pages/massage-two';
import Massageface from './components/pages/massage-face';
import Massageanti from './components/pages/massage-anti';
import Massagelpg from './components/pages/massage-lpg';
import Massagechildren from './components/pages/massage-children';
import Obuc from './components/pages/obuchenie';
import Cosmet from './components/pages/cosmetology';
import Podarok from './components/pages/podarok';
import Tatu from './components/pages/taty';
import Abon from './components/pages/abonym';
import Obucc from './components/pages/obuc-cos';
import Obuct from './components/pages/obuc-tat';
import Breadcrumbs from './components/pages/Breadcrumbs';
import Cart from "./components/pages/cart";
import Polyt from "./components/pages/polyt";
import Spa from "./components/pages/spa";
import SpaTwo from "./components/pages/SpaTwo";
import Sotrud from "./components/pages/sotrud";
import SearchPage from "./components/header/SearchPage";
import NotFoundPage from "./error";
import ScrollToTop from './ScrollToTop';


// const products = [
//   {
//     id: 1,
//     title: "Массаж для женщин",
//     duration: "1 час", // Длительность процедуры
//     serviceType: "Массаж", // Вид услуги
//     forWhom: "Для женщин", // Для кого
//     course: "true", // Курсы (если продукт является курсом)
//     price: "1500", // Цена
//     medicalEducation: "true", // Медицинское образование (true/false)
//     specialOccasion: "8 марта", // Специальные случаи (например, "8 марта", "14 февраля" и т.д.)
//     imgClass: "product-img-class" // Класс для изображения (если есть)
//   },
//   {
//     id: 2,
//     title: "Спа для двоих",
//     duration: "2 часа",
//     serviceType: "Спа",
//     forWhom: "Для двоих",
//     course: "false",
//     price: "3000",
//     medicalEducation: "false",
//     specialOccasion: "День Рождение",
//     imgClass: "product-img-class"
//   },
//   // Другие продукты...
// ];
// Описание возможных значений для каждого фильтра (не оставляйте поля пустыми если в них нечего не вписать, к примеру, если у вас карточка в поиске конкретно массаж, то есть не курсы массажа, вы не пишете в products - course:''. Объясняю почему, ваш поиск будет все равно отображать эту карточку при выборе course, т к он пытается понять надо или не надо ему обрабатывать этот запрос)
// Длительность процедуры (duration):
// Возможные значения: "1 час", "1 час 30 минут", "2 часа", "3 часа" и другие.
//     Пример: duration: "1 час"
// Вид услуги (serviceType):
// Возможные значения: "Массаж", "Спа", "Косметология" и другие.
//     Пример: serviceType: "Массаж"
// Для кого (forWhom):
// Возможные значения: "Для женщин", "Для мужчин", "Для детей", "Для двоих".
//     Пример: forWhom: "Для женщин"
// Курсы (course):
// Возможные значения: "true" (если продукт является курсом), "false" (если продукт не является курсом).
// Пример: course: "true"
// Цена (price):
// Значение: строка, представляющая цену продукта. Пример: price: "1500"
// Медицинское образование (medicalEducation):
// Возможные значения: "true" (если требуется медицинское образование), "false" (если не требуется медицинское образование).
// Пример: medicalEducation: "true"
// Специальные случаи (specialOccasion):
// Возможные значения: "8 марта", "14 февраля", "23 февраля", "Новый год", "для родителей", "День Рождение" и другие.
//     Пример: specialOccasion: "8 марта"


const App = () => {
  const [cartItems, setCartItems] = useState([]);
  const [city, setCity] = useState("Волгоград");
  const handleCityChange = (newCity) => {
    setCity(newCity);
  };
  const products = [

    { id: 1, title: "Подарочные сертификаты на СПА программы для двоих в Волгограде", price: 6000, desc: 'Цена зависит от города и выбранной услуги.',serviceType:'Спа',forWhom: ['Для двоих'], city: '' , imgClass: 'card__img-3 img-918'},
    { id: 2, title: "Подарочные сертификаты для мужчины в Волгограде", price: 3000, desc: 'Цена зависит от города и выбранной услуги.',serviceType:'Спа',forWhom: ['Для мужчин'], city: '' , imgClass: 'card__img-3 img-919'},
    { id: 3, title: "Подарочные сертификаты для девушки или женщине в Волгограде", price: 3000, desc: 'Цена зависит от города и выбранной услуги.',serviceType:'Спа',forWhom: ['Для женщин'], city: '' , imgClass: 'card__img-3 img-920'},

    { id: 4, title: "Массаж для двоих",spis1:'Классический массаж тела  ',spis2:'Оздоровительный массаж',spis3:'Лимфодренажный массаж ',spis4:'Антицеллюлитный массаж ',spis5:'Вакуумный массаж ',spis6:'Медовый массаж1 час 6000₽ ',spis7:'Релаксирующий массаж тела',spis8:'Спортивный массаж',spis9:'Моделирующий массаж тела  ',spis10:'Точечный массаж тела',spis11:'Антистрессовый массаж ', duration:'1час',  serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-216'},
    { id: 5, title: "Массаж-спа для двоих",spis1:'Тайский массаж ',spis2:'Испанский массаж ',spis3:'Креольский массаж(бамбуковыми палочками) ',spis4:'Индийский(маслянный) массаж',spis5:'Тибетский массаж с поющей чашей',spis6:'Турецкий мыльный массаж ',spis7:'Гавайский массаж ломи-ломи',spis8:'Аюрведческий спа-массаж',spis9:'Точечный массаж шиацу ',spis10:'Королевский массаж в 4 руки1 час 12000₽',spis11:'Стоун-массаж(горячими камнями) ', spis12:'Арома-массаж',spis13:'Массаж травянными мешочками',spis14:'Медовый спа-массаж',spis15:'Relax-массаж',duration:'1 час',  serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-217'},
    { id: 6, title: "Массаж лица для двоих",spis1:'Лимфодренажный массаж лица 30 мин.4000₽  ',spis2:'Классический массаж лица',spis3:'Косметический массаж лица ',spis4:'Пластический(миоскульптурный) массаж лица1 час 4000₽',spis5:'Буккальный массаж лица 30 мин.4000₽',spis6:'Массаж лица по Жаке',spis7:'Точечный массаж лица',spis8:'Хиромассаж лица 30 мин.4000₽',spis9:'Стоунтерапия для лица',spis10:'Ультразвуковой массаж лица ',spis11:'Вакуумный массаж лица', duration:'30 мин.',  serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-218'},
    { id:7, title: "Аппаратный массаж",spis1:'LPG-массаж 35 мин.6000₽ ',spis2:'Ультразвуковой массаж(кавитация) ',spis3:'Дарсонвализация 20 мин.1000₽ ',spis4:'Пневмомассаж(прессотерапия) ',spis5:'Миостимуляция ', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-219'},
    { id: 8, title: "Массаж для похудения на двоих",spis1:'Лимфодренажный1 час 5000₽ ',spis2:'Антицеллюлитный',spis3:'Баночный1 час 5000₽ ',spis4:'Медовый ',spis5:'Липоскульптурный1 час 7000₽',spis6:'Slim-массаж ', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-220'},
    { id: 9, title: "Массаж для лица",spis1:'Лимфодренажный массаж лица ',spis2:'Классический массаж лица',spis3:'Косметический массаж лица ',spis4:'Пластический(миоскульптурный) массаж лица1 час 2500₽',spis5:'Буккальный массаж лица',spis6:'Массаж лица по Жаке ',spis7:'Точечный массаж лица',spis8:'Японский массаж лица1 час 2500₽',spis9:'Стоунтерапия для лица',spis10:'Ультразвуковой массаж лица',spis11:'Вакуумный массаж лица', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-210'},
    { id: 10, title: "Массаж тела",spis1:'Классический массаж',spis2:'Лимфодренажный массаж ',spis3:'Антицеллюлитный массаж ',spis4:'Вакуумный массаж',spis5:'Медовый массаж 3000₽/1час',spis6:'Релаксирующий массаж',spis7:'Спортивный массаж',spis8:'Моделирующий массаж',spis9:'Точечный массаж',spis10:'Антистрессовый массаж ', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-211'},
    { id: 11, title: "Спа-массаж",spis1:'Стоун-массаж(горячими камнями) ',spis2:'Арома-массаж',spis3:'Массаж травянными мешочками',spis4:'Медовый спа-массаж',spis5:'Королевский массаж в 4-ре руки1 час 6000₽',spis6:'Точечный массаж шиацу',spis7:'Аюрведический массаж',spis8:'Гавайский массаж ломи-ломи ',spis9:'Турецкий мыльный массаж',spis10:'Тибетский массаж с поющей чашей ',spis11:'Индийский(маслянный) массаж ',spis12:'Креольский массаж(бамбуковыми палочками)',spis13:'Испанский массаж ',spis14:'Тайский массаж', duration:'1 час',  serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-86'},
    { id: 12, title: "Детский массаж",spis1:' Детский массаж до года до 30 мин. 500₽',spis2:'Детский массаж от 1 до 3 лет',spis3:' Детский массаж от 3 до 6 лет',spis4:'Детский массаж от 6 до 14 лет до1 часа 1500₽', duration:'30 мин.',  serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-213'},
    { id: 804, title: "Аппаратный массаж",spis1:' LPG-массаж',spis2:'Ультразвуковой массаж(кавитация)',spis3:'Дарсонвализация 20 мин. 500₽ ',spis4:'Пневмомассаж(прессотерапия) ',spis5:'Миостимуляция', duration:'30 мин.', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'],desc: '', city: '' , imgClass: 'card__img-3 card__img-search-214'},
    { id: 805, title: "Массаж в коррекции фигуры",spis1:'Лимфодренажный ',spis2:'Антицеллюлитный ',spis3:'Баночный ',spis4:'Медовый',spis5:'Липоскульптурный',spis6:' Slim-массаж',serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-215'},

    { id: 13, title: 'Курсы косметик-эстетист в Адлере Заочно-дистанционно', price: 27000, duration: '320 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 14, title: 'Курсы косметик-эстетист в Адлере Очно в группе', price: 55000, duration: '320 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 15, title: 'Курсы косметик-эстетист в Адлере Очно-индивидуально', price: 75000, duration: '320 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 16, title: 'Курс Сестринское дело в косметологии Очно в группе', price: 75000, duration: '288 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 17, title: 'Курс Сестринское дело в косметологии Очно-индивидуально ', price: 95000, duration: '288 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 18, title: 'Курсы аппаратной косметологии Заочно-дистанционно', price: 12000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 19, title: 'Курсы аппаратной косметологии Очно в группе ', price: 25000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 20, title: 'Курсы аппаратной косметологии Очно-индивидуально ', price: 35000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 21, title: 'Курсы Инъекционные методы в косметологии Заочно-дистанционно', price: 22000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 22, title: 'Курсы Инъекционные методы в косметологии Очно в группе', price: 45000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 23, title: 'Курсы Инъекционные методы в косметологии Очно-индивидуально ', price: 65000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 24, title: 'Курсы Мезотерапии Заочно-дистанционно', price: 10000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 25, title: 'Курсы Мезотерапии Очно в группе', price: 19000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 26, title: 'Курсы Мезотерапии Очно-индивидуально', price: 29000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 27, title: 'Курсы химические пилинги Заочно-дистанционно ', price: 7000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']} ,
    { id: 28, title: 'Курсы химические пилинги Очно в группе', price: 15000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 29, title: 'Курсы химические пилинги Очно индивидуально ', price: 25000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 30, title: 'Курсы шугаринга и восковой депиляции Заочно-дистанционно ', price: 10000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 31, title: 'Курсы шугаринга и восковой депиляции Очно в группе ', price: 19000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 32, title: 'Курсы шугаринга и восковой депиляции Очно-индивидуально  ', price: 29000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 33, title: 'Курсы Детского массажа Заочно-дистационно ', price: 12000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 34, title: 'Курсы Детского массажа Очно в группе  ', price: 29000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 35, title: 'Курсы Детского массажа Очно-индивидуально  ', price: 39000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 36, title: 'Курсы Медицинского массажа Очно в группе ', price: 55000, duration: '288 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 37, title: 'Курсы Медицинского массажа Очно-индивидуально', price: 75000, duration: '288 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 38, title: 'Курсы Классического массажа лица Заочно-дистанционно', price: 7000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 39, title: 'Курсы Классического массажа лица Очно в группе ', price: 15000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 40, title: 'Курсы Классического массажа лица Очно-индивидуально ', price: 25000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 41, title: 'Курсы пластический массаж лица Заочно-дистанционно ', price: 7000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 42, title: 'Курсы пластический массаж лица Очно в группе ', price: 15000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 43, title: 'Курсы пластический массаж лица Очно-индивидуально', price: 25000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 44, title: 'Курсы Буккального массажа лица Заочно-дистанционно ', price: 7000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 45, title: 'Курсы Буккального массажа лица Очно в группе', price: 15000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 46, title: 'Курсы Буккального массажа лица Очно-индивидуально', price: 25000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 47, title: 'Курсы Японского массажа лица Заочно-дистанционно', price: 7000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 48, title: 'Курсы Японского массажа лица Очно в группе' , price: 15000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 49, title: 'Курсы Японского массажа лица Очно-индивидуально', price: 25000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 50, title: 'Курсы массажа Шиацу Заочно-дистанционно', price: 7000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 51, title: 'Курсы массажа Шиацу Очно в группе', price: 15000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 52, title: 'Курсы массажа Шиацу Очно-индивидуально ', price: 25000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 53, title: 'Курсы Антицеллюлитного массажа Заочно-дистанционно', price: 7000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 54, title: 'Курсы Антицеллюлитного массажа Очно в группе', price: 15000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 55, title: 'Курсы Антицеллюлитного массажа Очно-индивидуально ', price: 25000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 56, title: 'Курсы классический массаж Заочно-дистанционно', price: 12000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 57, title: 'Курсы классический массаж Очно в группе', price: 25000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 58, title: 'Курсы классический массаж Очно-индивидуально', price: 35000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 59, title: 'Курсы Спа специалиста Заочно-дистанционно', price: 17000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 60, title: 'Курсы Спа специалиста Очно в группе', price: 35000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10048, title: 'Курсы Спа специалиста Очно-индивидуально', price: 55000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10049, title: 'Курсы Тайский массаж Заочно-дистанционно ', price: 12000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10050, title: 'Курсы Тайский массаж Очно в группе', price: 25000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10051, title: 'Курсы Тайский массаж Очно-индивидуально', price: 35000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10052, title: 'Курсы Тибетский массаж Заочно-дистанционно ', price: 12000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10053, title: 'Курсы Тибетский массаж Очно в группе ', price: 25000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10054, title: 'Курсы Тибетский массаж Очно-индивидуально', price: 35000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10055, title: 'Курсы Спа специалиста Заочно-дистанционно', price: 7000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10056, title: 'Курсы Спа специалиста Очно в группе', price: 15000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10057, title: 'Курсы Спа специалиста Очно-индивидуально', price: 25000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10058, title: 'Курсы Стоунтерапия Заочно-дистанционно', price: 7000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10059, title: 'Курсы Стоунтерапия Очно в группе', price: 15000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10060, title: 'Курсы Стоунтерапия Очно-индивидуально 25000₽', price: 25000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10061, title: 'Обучение креольскому массажу Заочно-дистанционно ', price: 7000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10062, title: 'Обучение креольскому массажу Очно в группе ', price: 15000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10063, title: 'Обучение креольскому массажу Очно-индивидуально', price: 25000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10064, title: 'Курсы основных видов массажа Заочно-дистанционно', price: 22000, duration: ' 144 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10065, title: 'Курсы основных видов массажа Очно в группе', price: 45000, duration: ' 144 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10066, title: 'Курсы основных видов массажа Очно-индивидуально ', price: 65000, duration: ' 144 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10067, title: 'Обучение массажу в коррекции фигуры Заочно-дистанционно', price: 19000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10068, title: 'Обучение массажу в коррекции фигуры Очно в группе', price: 35000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10069, title: 'Обучение массажу в коррекции фигуры Очно-индивидуально', price: 55000, duration: '72 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10070, title: 'Курсы лимфодренажного массажа Заочно-дистанционно', price: 7000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10071, title: 'Курсы лимфодренажного массажа Очно в группе', price: 15000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10072, title: 'Курсы лимфодренажного массажа Очно-индивидуально ', price: 25000, duration: '16 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10073, title: 'Курсы lpg-массаж обучение Заочно-дистанционно', price: 7000, duration: '16  ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10074, title: 'Курсы lpg-массаж обучение Очно в группе ', price: 15000, duration: '16  ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10075, title: 'Курсы lpg-массаж обучение Очно-индивидуально ', price: 25000, duration: '16  ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10076, title: 'Курсы спортивного массажа Заочно-дистанционно', price: 9000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10077, title: 'Курсы спортивного массажа Очно в группе', price: 18000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 10078, title: 'Курсы спортивного массажа Очно-индивидуально', price: 28000, duration: '36 ак.часа', imgClass: 'card__img-search-197', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},



    { id: 555, title: "Абонемент 1 месяц", price: 35000, serviceType:'Абонемент',forWhom: ['Для женщин','Для мужчин','Для двоих'],desc: '', city: '' , imgClass: 'card__img-3 card__img-search-555'},
    { id: 556, title: "Абонемент 3 месяца", price: 75000,serviceType:'Абонемент',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-155'},
    { id: 557, title: "Абонемент 1 год", serviceType:'Абонемент',forWhom: ['Для женщин','Для мужчин','Для двоих'],price: 90000, desc: '', city: '' , imgClass: 'card__img-3 card__img-search-557'},


    { id: 154, title: "СПА-программа \"Счастье быть\"",spis1: 'пилинг перчаткой kessa-20минут',spis2: 'relax-массаж с аромамаслом-40минут',spis3: 'aroma-релаксация -30 минут',spis4: 'очищение лица нежной пенкой-10минут',spis5: 'relax-массаж лица с кокосовым молочком-20минутv',spis6: 'комплимент для гостя.', price:  5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', duration:' 2 часа', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-154'},
    { id: 155, title: "Японская СПА программа \"Сакура\"",duration:' 1.30 минут',spis1: 'Согревающий массаж — 30 минут',spis2: 'Японский массаж с aroma релаксацией — 1 час',spis3: 'Комплимент для гостя.', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-155'},
    { id: 156, title: "Тибетская СПА программа \"Вершина мира\"- 1.30 часа",duration:' 1.30 минут',spis1: 'Cогревающий массаж - 30 минут',spis2: 'Тибетский массаж с поющими чашами и aroma релаксация - 1 час',spis3: 'Комплимент для гостя.', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-156'},
    { id: 157, title: "Марокканская Спа программа-Радость Марракеша",duration:' 1.30 минут',spis1: 'Согревающий массаж - 30 минут',spis2: 'Марокканский массаж c аргановым маслом— 1 час',spis3: 'Комплимент для гостя.', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-157'},
    { id: 158, title: "Креольская СПА программа \"Сейшилы Spa\"",duration:' 1.30 минут',spis1: 'Согревающий массаж - 30 минут',spis2: 'Креольский массаж бамбуковыми палочками c aroma-relax— 1 час',spis3: 'Комплимент для гостя.', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-158'},
    { id: 159, title: "Индийская СПА программа \"На Гоа\"",duration:' 1.30 минут',spis1: 'Пилинг-массаж - 30 минут',spis2: 'Индийский массаж на горячем масле с aroma-релаксацией - 1 час',spis3: 'Комплимент для гостя.', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: ' 8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-159'},
    { id: 160, title: "Испанская СПА программа \"Коррида\"",duration:'1.30 минут',spis1: 'Согревающий массаж — 30 минут',spis2: 'Испанский массаж на Традиционных aroma маслах — 1 час',spis3: 'Комплимент для гостя.', price: 4000, serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'],desc: 'Для двоих —8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-160'},
    { id: 161, title: "СПА программа \"Energy\"",duration:'1.30 минут',spis1: 'аккупунктурный массаж стоп - 30 минут',spis2: 'спортивный массаж с аромарелаксацией - 60 минут',spis3: 'Комплимент для гостя',spis4: '',spis5: '',spis6: '',spis7: '', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-161'},
    { id: 162, title: "СПА программа \"Медитация\"",duration:' 1.30 минут',spis1: 'Согревающий массаж — 30 минут',spis2: 'Relax-массаж и медитация — 1 час',spis3: 'Комплимент для гостя',spis4: '',spis5: '',spis6: '',spis7: '', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-162'},
    { id: 163, title: "СПА-программа Тайская сказка",duration:' 2 часа',spis1: 'согревающий массаж - 30 минут;',spis2: 'пилинг-массаж - 30 минут;',spis3: 'тайский арома-массаж с аромарелаксацией- 1 час;',spis4: 'Комплимент для гостей',spis5: '',spis6: '',spis7: '', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-163'},
    { id: 164, title: "СПА-программа Тропическая ночь",duration:' 1.30 минут',spis1: 'массаж горячими камнями с расстановкой по точкам-30минут',spis2: 'Relax-массаж на нежном кокосовом молочке-30минут',spis3: 'aroma-релаксация -30 минут',spis4: 'комплимент для гостя.',spis5: '',spis6: '',spis7: '', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-164'},
    { id: 165, title: "СПА программа \"Жизнь в шоколаде\"",duration:' 2 часа',spis1: 'согревающий стоун-массаж горячими камнями - 30 минут;',spis2: 'испанский массаж - 30 минут;',spis3: 'пилинг перчаткой Kessa - 30 минут;',spis4: 'обертывание с воздушным шоколадом - 30 минут',spis5: 'relax-массаж лица на шоколаде или кокосе(во время обертывания)',spis6: 'Комплимент для гостя',spis7: '', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-165'},
    { id: 167, title: "СПА программа \"Монарх\"",duration:' 2 часа',spis1: 'Согревающий пилинг-массаж - 1 час',spis2: 'Аюрведческий массаж стоп - 30 минут',spis3: 'Комплимент для гостя',spis4: 'королевский массаж с Vip-маслами на выбор - 30минут',spis5: 'Комплимент для гостя.',spis6: '',spis7: '', price: 6000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-166'},
    { id: 168, title: "СПА программа \"Новогодний глинтвейн\"",duration:' 2 часа',spis1: 'Согревающий массаж - 1 час;',spis2: 'Relax-массаж и медитация — 1 час',spis3: 'Обновляющий пилинг-массаж - 30 минут;',spis4: 'Массаж «Жато Велнесс» с горячим Глинтвейном (антистрессовый,глубокая проработка мышц) - 30 минут;',spis5: 'Новогодний комплимент для гостя.',spis6: '',spis7: '', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-167'},
    { id: 169, title: "СПА программа \"Rehabilitation\"",duration:' 1.30 минут',spis1: 'Согревающий пилинг-массаж — 1 час',spis2: 'массаж Жато Велнесс с aroma-релаксацией — 30 минут',spis3: 'Комплимент для гостя.',spis4: '',spis5: '',spis6: '',spis7: '', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-168'},
    { id: 170, title: "СПА программа \"Совершенство\"",duration:' 2 часа',spis1: 'согревающий массаж - 30 минут;',spis2: 'отчищающий пилинг-массаж - 30 минут;',spis3: 'Моделирующий массаж - 30 минут;',spis4: 'Обёртывание по показаниям: Крио-активное,термо-активное, дренажное - 30 минут;',spis5: 'Комплимент для гостя',spis6: '',spis7: '', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-169'},
    { id: 171, title: "СПА-программа Второе дыхание",duration:' 2 часа',spis1: 'согревающий массаж - 30 минут;',spis2: 'Традиционный Тибетский массаж - 1 час;',spis3: 'Антиоксидантное обертывание с вытяжкой из водорослей и зеленого чая - 30 минут;',spis4: 'обертывание с воздушным шоколадом - 30 минут',spis5: 'Комплимент для гостя.',spis6: '',spis7: '', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-170'},
    { id: 172, title: "СПА программа Под пальмой",duration:' 1.30 минут' ,spis1: 'массаж бамбуковыми палочками и горячими камнями — 30 минут',spis2: 'массаж тела на кокосовом молочке с aroma релаксацией — 1 час',spis3: 'комплимент для гостя.',spis4: '',spis5: '',spis6: '', price:  4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-171'},
    { id: 173, title: "СПА программа для пары Рай на двоих",duration:' 2 часа',spis1: 'для двоих - Согревающий пилинг-массаж - 1 час',spis2: 'для нее - Тайский маслянный aroma-массаж горячим маслом кокоса - 30 минут',spis3: 'Для него - Тайский-Йога массаж и aroma-релаксация- 30 минут',spis4:'для двоих - Традиционный Тайский массаж лица и головы - 30 минут',spis5:'Комплимент для пары', price: 10000, serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'],desc: '', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-172'},
    { id: 174, title: "СПА программа для пары \"Сладкая парочка\"",duration:' 2 часа',spis1: 'пилинг-массаж - 30минут',spis2: 'для него - массаж с элементами Тайского на кокосовом масле c aroma релаксацией - 1 час',spis3: 'для нее - расслабляющий массаж на шоколаде с мятой или нежным фруктовым муссом с aroma релаксацией - 1 час',spis4:'для него - точечный массаж стоп и рук - 30минут',spis5:'для нее - комплексный уход за лицом: расслабляющий массаж лица, увлажняющая крем-маска - 30минут',spis6:'комплимент для пары.', price: 10000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-173'},
    { id: 175, title: "СПА программа \"Приключения в Тае\"",duration:' 2 часа',spis1: 'для двоих - согревающий пилинг-массаж - 1 час',spis2: 'для двоих - Relax-массаж стоп с кокосовым молочком - 30 минут',spis3: 'для него — Традиционный Тайский массаж лица и головы - 30 минут',spis4:'для неё — Тайский маслянный aroma-массаж на горячем масле кокоса - 30 минут',spis5:'для неё — Тайский маслянный aroma-массаж на горячем масле кокоса - 30 минут', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-174'},
    { id: 176, title: "СПА программа \"Летнее настроение\"",duration:' 1.30 минут',spis1: 'согревающий пилинг массаж - 1 час',spis2: 'relax-массаж с экзотическими фруктами - 30 минут',spis3: 'Комплимент для гостя.', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-175'},
    { id: 177, title: "СПА программа \"Кокосовый рай\"",duration:' 2 часа',spis1: 'согревающий пилинг массаж — 1 час;',spis2: 'Тайский маслянный массаж на горячем масле на выбор — 1 час;',spis3: 'Традиционный комплимент для гостя.', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-176'},
    { id: 178, title: "СПА программа \"Путешествие на Гоа\"",duration:' 2 часа',spis1: 'согревающий массаж - 1 час;',spis2: 'Индийский маслянный массаж с aroma релаксацией — 1 час;',spis3: 'Традиционный комплимент для гостя.\n', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-177'},
    { id: 179, title: "СПА программа \"Relax\"",duration:'1.30 минут',spis1: 'Согревающий массаж — 30 минут',spis2: 'аккупунктурный массаж с aroma релаксацией - 1 час',spis3: 'Комплимент для гостя',spis4: '',spis5: '',spis6: '',spis7: '', price: 4000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —8000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-178'},
    { id: 180, title: "СПА программа \"Slimming day\"",duration:' 2 часа',spis1: 'стоун-массаж горячими камнями - 30 минут;',spis2: 'отчищающий пилинг-массаж — 30 минут;',spis3: 'антицеллюлитный Slim-массаж проблемных зон с контрастным обёртыванием — 60 минут;',spis4: 'Комплимент для гостя.',spis5: '',spis6: '',spis7: '', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-179'},
    { id: 181, title: "СПА программа \"Цитрусовый взрыв\"",duration:' 2 часа',spis1: 'согревающий пилинг-массаж - 1 час;',spis2: 'Моделирующий массаж на цитрусовых маслах на выбор — 1 час;',spis3: 'Комплимент для гостя.\n',spis4: '',spis5: '',spis6: '',spis7: '', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-180'},
    { id: 182, title: "СПА программа \"Путешествие на Тибет\"",duration:' 2 часа',spis1: 'согревающий пилинг-массаж - 1 час;',spis2: 'Тибетский маслянный массаж с горячим маслом на выбор — 1 час;',spis3: 'Комплимент для гостя.',spis4: '',spis5: '',spis6: '',spis7: '', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-181'},
    { id: 183, title: "Vip СПА программа \"Полёт на Мальдивы\"",duration:' 2 часа',spis1: 'согревающий пилинг-массаж — 1 час;',spis2: 'Мальдивский массаж на горячем масле «Полинезия» — 1 час;',spis3: 'Комплимент для гостя;',spis4: '',spis5: '',spis6: '',spis7: '', price: 5000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —10000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-182'},
    { id: 189, title: "Vip СПА программа \"Коста-Рика\"",duration:'3 часа',spis1: 'Согревающий массаж — 1 час;',spis2: 'Отчищающий пилинг-массаж — 30 минут;',spis3: 'Креольский массаж с горячим маслом миндаля — 1 час;',spis4: 'Марроканский массаж лица — 30 минут;',spis5: 'Комплимент для гостя.',spis6: '',spis7: '', price: 8000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —15000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-19000'},
    { id: 184, title: "Vip СПА программа \"Атлантида\"",duration:' 3 часа',spis1: 'Согревающий массаж — 1 час;',spis2: 'Пилинг-массаж — 30 минут;',spis3: 'Тайский маслянный аромамассаж нагорячих Vip-маслах - 1 час;',spis4:'Испанский массаж лица — 30 минут;',spis5:'Комплимент для гостя',spis6:'', price: 8000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —15000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-184'},
    { id: 185, title: "Vip СПА программа \"Сибирь\"",duration:' 3 часа',spis1: 'согревающий пилинг-массаж - 1 час',spis2: 'Стоун-массаж горячими камнями — 30 минут.',spis3: 'Русский массаж с Сибирскими ягодами — 1 час;',spis4: 'Массаж вениками - 30 минут',spis5: 'Комплимент для гостя.',spis6: '',spis7: '', price: 8000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих —15000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-185'},
    { id: 186, title: "Vip СПА программа \"Семь чудес света\"",duration:' 3 часа',spis1: 'Обновляющий пилинг-массаж — 30 минут;',spis2: 'Аюрведческий-массаж с ароматерапией — 1.30 час;',spis3: 'Aroma-relax на Vip-маслах — 1 час',spis4: '',spis5: '',spis6: '',spis7: '', price: 8000, serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'],desc: 'Для двоих — 15000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-186'},
    { id: 187, title: "Vip СПА программа \"Жемчужина моря\"",duration:' 3 часа',spis1: 'согревающий массаж — 1 час;',spis2: 'очищающий пилинг массаж рукавичками Kessa — 30 минут;',spis3: 'тайский или индийский массаж на выбор и обёртывание с экстрактом жемчуга и лепестками розы — 60 минут;',spis4: 'массаж лица с экстрактом жемчуга и лепестками розы — 30 минут;',spis5: 'Комплимент для гостей',spis6: '',spis7: '', price: 8000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 15000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-187'},
    { id: 188, title: "Vip СПА программа \"Роскошь Океана\"",duration:' 3 часа',spis1: 'согревающий пилинг-массаж — 30 минут;',spis2: 'стоун-массаж горячими камнями — 30 минут;',spis3: 'relax массаж и питание тела протеином черной икры — 1.30 часа;',spis4: 'массаж лица воздушным кремом с протеином черной икры — 30 минут;',spis5: '',spis6: '',spis7: '', price: 8000,serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: 'Для двоих — 15000₽', city: 'Место-Волгоград' , imgClass: 'card__img-3 card__img-search-188'},

    { id: 915, title: "Обучение массажу", price: "по договоренности", desc: '', city: '' , imgClass: 'card__img-5 obuc-img915'},
    { id: 25001, title: "Спа процедуры и массаж для двоих в spa салоне Волгограда", spis1:'пилинг-массаж перчаткой kessa-30минут',spis2:'relax-массаж с арома-релаксацией-1 час',spis3:'relax-массаж лица-30минут',spis4:'Комплимент для гостей.', rice: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-189'},
    { id: 25002, title: "Японская СПА программа \"Сакура\"",spis1:'Согревающий массаж — 30 минут',spis2:'Японский массаж с aroma релаксацией— 1 час',spis3:'Комплимент для гостей.', price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-190'},
    { id: 25003, title: "Тибетская СПА программа \"Вершина мира\"- 1.30 часа\n",spis1:'согревающий массаж - 30 минут',spis2:'Тибетский массаж с поющими чашами и aroma релаксацией— 1 час',spis3:'Компплимент для гостей.', price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-191'},
    { id: 25004, title: "Марокканская Спа программа-Радость Марракеша",spis1:'Согревающий массаж - 30 минут',spis2:'Марокканский массаж c аргановым маслом— 1 час',spis3:'Комплимент для гостей.', price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-192'},
    { id: 25005, title: "Креольская СПА программа \"Сейшилы Spa\"",spis1:'согревающий массаж - 30 минут',spis2:'Креольский массаж бамбуковыми палочками c aroma-relax— 1 час',spis3:'Комплимент для гостей.', price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-193'},
    { id: 25006, title: "Индийская СПА программа \"На Гоа\"", spis1:'пилинг-массаж - 30 минут',spis2:'Индийский массаж на горячем масле с aroma-релаксацией - 1 час',spis3:'Комплимент для гостей.',price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-194'},
    { id: 25007, title: "Испанская СПА программа \"Коррида\"", spis1:'Согревающий массаж — 30 минут',spis2:'Испанский массаж с aroma-релаксацией — 1 час',spis3:'Комплимент для гостей.',price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-195'},
    { id: 25008, title: "СПА программа \"Energy\"", spis1:'аккупунктурный массаж стоп - 30 минут',spis2:'спортивный массаж с аромарелаксацией - 1 час',spis3:'Комплимент для гостей',price:  8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-196'},
    { id: 25009, title: "СПА программа \"Медитация\"", spis1:'согревающий массаж — 30 минут',spis2:'Relax-массаж и медитация — 1 час',spis3:'Комплимент для гостей',price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-197'},
    { id: 250010, title: "СПА программа \"Тайская сказка\"", spis1:'согревающий пилинг-массаж - 30 минут;',spis2:'тайский арома-массаж - 30 минут;',spis3:'тайский массаж рук, стоп и головы - 30 минут;',spis4:'aroma-релаксация всего тела - 30 минут;',spis5:'Комплимент для гостей',price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-198'},
    { id: 250011, title: "СПА программа \"Тропическая ночь\"",spis1:'массаж горячими камнями с расстановкой по точкам-30минут',spis2:'Relax-массаж на нежном кокосовом молочке-30минут',spis3:'aroma-релаксация-30 минут',spis4:'Комплимент для гостей.', price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-199'},
    { id: 250012, title: "СПА программа \"Жизнь в шоколаде\"", spis1:'согревающий стоун-массаж горячими камнями - 30 минут;',spis2:'точечный массаж стоп и рук - 30 минут;',spis3:'пилинг-массаж перчаткой Kessa - 30 минут;',spis4:'обертывание с воздушным шоколадом - 30 минут',spis5:'relax-массаж лица на воздушном шоколаде(во время обертывания)',spis6:'Комплимент для гостей',price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-200'},
    { id: 250013, title: "СПА программа \"Монарх\"",spis1:'согревающий пилинг-массаж - 1 час',spis2:'Аюрведческий массаж стоп - 30 минут',spis3:'королевский массаж с Vip-маслами на выбор - 30минут',spis4:'Комплимент для гостей.', price: 15000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-166'},
    { id: 250014, title: "СПА программа \"Новогодний глинтвейн\"", spis1:'согревающий массаж - 1 час;',spis2:'Обновляющий пилинг-массаж - 30 минут;',spis3:'Массаж «Жато Велнесс» с горячим Глинтвейном (антистрессовый,глубокая проработка мышц) - 30 минут;',spis4:'Новогодний комплимент для гостей.',price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-202'},
    { id: 250015, title: "СПА программа \"Rehabilitation\"", spis1:'согревающий пилинг-массаж — 30 минут',spis2:'массаж Жато Велнесс с aroma релаксацией — 1 час',spis3:'Комплимент для гостей.',price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-203'},
    { id: 250016, title: "СПА программа \"Совершенство\"", spis1:'согревающий массаж - 30 минут;',spis2:'отчищающий пилинг-массаж - 30 минут;',spis3:'Моделирующий массаж - 30 минут;',spis4:'Обёртывание по показаниям: Крио-активное,термо-активное, дренажное - 30 минут;',spis5:'Комплимент для гостей',price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-204'},
    { id: 250017, title: "СПА программа \"Второе дыхание\"", spis1:'согревающий массаж - 1 час;',spis2:'Тибетский массаж - 30 минут;',spis3:'Антиоксидантное обертывание с вытяжкой из водорослей и зеленого чая - 30 минут;',spis4:'Комплимент для гостей.',price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-205'},
    { id: 250081, title: "СПА программа \"Под пальмой\"", spis1:'массаж бамбуковыми палочками и горячими камнями — 30 минут',spis2:'массаж тела на кокосовом молочке с aroma релаксацией— 1 час',spis3:'Комплимент для гостей.',price:  8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-206'},
    { id: 250018, title: "СПА программа для пары \"Рай на двоих\"",spis1:'для двоих - Согревающий пилинг-массаж - 30 минут',spis2:'для нее - Тайский маслянный aroma-массаж горячим маслом кокоса - 1 час',spis3:'Для него - Тайский-Йога массаж и aroma-релаксация - 1 час',spis4:'для двоих - Тайский массаж лица и головы - 30 минут',spis5:'Комплимент для пары', price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-207'},
    { id: 250019, title: "СПА программа для пары \"Сладкая парочка\"", spis1:'пилинг-массаж - 30минут',spis2:'для него - массаж с элементами Тайского на кокосовом масле c aroma релаксацией - 1 час',spis3:'для нее - расслабляющий массаж на шоколаде с мятой или нежным фруктовым муссом с aroma релаксацией - 1 час',spis4:'для него - точечный массаж стоп и рук - 30минут',spis5:'для нее - комплексный уход за лицом: расслабляющий массаж лица, увлажняющая крем-маска - 30минут',spis6:'Комплимент для пары.',price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-208'},
    { id: 250022, title: "СПА программа \"Приключения в Тае\"",spis1:'для двоих - согревающий пилинг-массаж - 1 час',spis2:'для двоих - Relax-массаж стоп с кокосовым молочком - 30 минут',spis3:'для него — Тайский массаж лица и головы - 30 минут',spis4:'для неё — Тайский маслянный aroma-массаж на горячем масле кокоса - 30 минут',spis5:'Комплимент для двоих.', price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-209'},
    { id: 250033, title: "СПА программа \"Летнее настроение\"", spis1:'согревающий пилинг массаж - 1 час',spis2:'relax-массаж с экзотическими фруктами - 30 минут',spis3:'Комплимент для гостей.',price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-175'},
    { id: 250021, title: "СПА программа \"Кокосовый рай\"", spis1:'согревающий пилинг массаж — 1 час;',spis2:'Тайский маслянный массаж на горячем масле на выбор — 1 час;',spis3:'Комплимент для гостей',price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-176'},
    { id: 250025, title: "СПА программа \"Путешествие на Гоа\"", spis1:'согревающий массаж - 1 час;',spis2:'Индийский маслянный массаж с aroma релаксацией — 1 час;',spis3:'Комплимент для гостей.',price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-177'},
    { id: 250052, title: "СПА программа \"Relax\"", spis1:'Согревающий массаж — 30 минут',spis2:'аккупунктурный массаж с aroma релаксацией - 1 час',spis3:'Комплимент для гостей',price: 8000, desc: ' 1.30 минут', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-178'},
    { id: 250087, title: "СПА программа \"Slimming day\"", spis1:'стоун-массаж горячими камнями - 30 минут;',spis2:'отчищающий пилинг-массаж — 30 минут;',spis3:'антицеллюлитный Slim-массаж проблемных зон с контрастным обёртыванием — 1 час;',spis4:'Комплимент для гостей',price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-179'},
    { id: 250048, title: "СПА программа \"Цитрусовый взрыв\"",spis1:'согревающий пилинг-массаж - 1 час;',spis2:'Моделирующий массаж на цитрусовых маслах на выбор — 1 час;',spis3:'Комплимент для гостей.', price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-180'},
    { id: 250099, title: "СПА программа \"Путешествие на Тибет\"", spis1:'согревающий пилинг-массаж - 1 час;',spis2:'Тибетский маслянный массаж с горячим маслом на выбор — 1 час;',spis3:'Комплимент для гостей.',price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-181'},
    { id: 250088, title: "Vip СПА программа \"Полёт на Мальдивы\"",spis1:'согревающий пилинг-массаж — 1 час;',spis2:'Мальдивский массаж на горячем масле «Полинезия» — 1 час;',spis3:'Комплимент для гостей;', price: 10000, desc: ' 2 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-182'},
    { id: 250044, title: "Vip СПА программа \"Коста-Рика\"", spis1:'Согревающий массаж — 1 час;',spis2:'отчищающий пилинг-массаж — 30 минут;',spis3:'Креольский массаж с горячим маслом миндаля — 1 час;',spis4:'Марроканский массаж лица — 30 минут',spis5:'Комплимент для гостей.',price: 15000, desc: ' 3 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-183'},
    { id: 250045, title: "Vip СПА программа \"Атлантида\"", spis1:'согревающий массаж — 1 час;',spis2:'Пилинг-массаж всего тела — 30 минут;',spis3:'Тайский маслянный аромамассаж на горячих Vip-маслах - 1 час;',spis4:'Испанский массаж лица — 30 минут;',spis5:'Комплимент для гостей',price: 15000, desc: ' 3 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-184'},
    { id: 250046, title: "Vip СПА программа \"Сибирь\"", spis1:'согревающий пилинг-массаж - 30 минут',spis2:'Стоун-массаж горячими камнями — 30 минут.',spis3:'Русский массаж с Сибирскими ягодами — 1.30 часа;',spis4:'Массаж вениками - 30 минут',spis5:'Комплимент для гостей;',price: 15000, desc: ' 3 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-185'},
    { id: 250047, title: "Vip СПА программа \"Семь чудес света\"", spis1:'',spis2:'',spis3:'',price: 2500, desc: '', city: '' , imgClass: 'card__img-3 card__img-search-221'},
    { id: 250084, title: "Vip СПА программа \"Жемчужина моря\"", spis1:'согревающий массаж — 1 час;',spis2:'очищающий пилинг массаж рукавичками Kessa — 30 минут;',spis3:'тайский или индийский массаж на выбор и обёртывание с экстрактом жемчуга и лепестками розы — 60 минут;', spis4:'массаж лица с экстрактом жемчуга и лепестками розы — 30 минут;',spis5:'комплимент для гостей',price: 15000, desc: ' 3 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-222'},
    { id: 250082, title: "Vip СПА программа \"Роскошь Океана\"",spis1:'согревающий пилинг-массаж — 1 час;',spis2:'стоун-массаж горячими камнями — 30 минут;',spis3:'relax массаж и питание тела протеином черной икры — 1 час;',spis4:'массаж лица воздушным кремом с протеином черной икры — 30 минут;', price: 15000, desc: ' 3 часа', city: 'Место —Волгоград' , imgClass: 'card__img-3 card__img-search-223'},

    { id: 900, title: "Косметология для лица",spis1:'Ультразвуковая чистка ',spis2:'Механическая чистка1 час. 3000₽',spis3:'Комбинированная чистка1 час 30 минут 3500₽ ',spis4:'Аппаратный массаж лица 30 мин. от 1250₽',spis5:'Косметический массаж лица 30 мин.от 1250₽',spis6:'Скульптурный-пластический массаж лица1 час 3000₽ ',spis7:'RF-лифтинг',spis8:'Дарсонвализация лица 15-20 мин. 500₽',spis9:'Дарсонвализация волосистой части головы 15-20 мин. 500₽',spis10:'Ультрафонофорез',spis11:'Алмазный пилинг',spis12:'Химический пилинг от 1500₽',spis13:'Карбокситерапия 30 мин. 3500₽',spis14:'Уходовые маски для лица 10-30 мин. от 500₽',spis15:'Демакияж лица 10 мин. 200₽',spis16:'Биоревитализация от 2500₽',spis17:'Мезотерапия ', serviceType:'Спа',forWhom: ['Для женщин','Для мужчин','Для двоих'], price: 1500, desc: '', city: '' , imgClass: 'card__img-3 card__img-search-900'},
    { id: 901, title: "Косметология для тела",spis1:'Кавитация ',spis2:'LPG-массаж 35 мин. от 2500₽',spis3:'RF-лифтинг',spis4:'Липолитики от 1500₽', serviceType:'Спа',forWhom: ['Для женщин','Для мужчин','Для двоих'], price: 1500, desc: '', city: '' , imgClass: 'card__img-3 card__img-search-901'},
    { id: 902, title: "Депиляция воском",spis1:'Ноги (полностью) 30-50 мин.1500₽ ',spis2:'Голень 20-30 мин.1000₽',spis3:'Руки (полностью) 30 мин.600₽ ',spis4:'Руки (до локтя) ',spis5:'Подмышки ',spis6:'Бикини (классическое) 30-50 мин.100₽ ',spis7:'Бикини (глубокое) 40-60 мин.1500₽',spis8:'Живот (полоска) 5 мин.300₽',spis9:'Живот (полностью)',spis10:'Лицо 7-10 мин.500₽', serviceType:'Спа',forWhom: ['Для женщин','Для мужчин','Для двоих'],price: 500, desc: '', city: '' , imgClass: 'card__img-3 card__img-search-902'},
    { id: 903, title: "Тату надпись", price: 1500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-1'},
    { id: 904, title: "Тату на запястье", price: 1500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-2'},
    { id: 905, title: "Тату на шее", price: 1500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-3'},
    { id: 906, title: "Тату на руке", price: 1500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-4'},
    { id: 907, title: "Тату на плечо", price: 2500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-5'},
    { id: 908, title: "Тату рукав", price: 2500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-6'},
    { id: 909, title: "Тату на ноге", price: 2500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-7'},
    { id: 910, title: "Тату на спине", price: 2500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-8'},
    { id: 911, title: "Тату на грудь", price: 2500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-9'},
    { id: 912, title: "Перекрытие тату", price: 2500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-10'},
    { id: 913, title: "Коррекция тату", price: 3500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-11'},
    { id: 914, title: "Заказать тату", price: 1500, desc: '',serviceType:'Тату', city: '' , imgClass: 'card__img-5 tat-12'},
    // { id: 9999, title: 'Сертификат ', price: 1', duration: '', imgClass: 'card__img-search-1', serviceType:'', forWhom: '', medicalEducation: false, specialOccasion: [] },
    // { id: 9998, title: 'Сертификат на массаж', price: 1p', duration: '', imgClass: 'card__img-search-2', serviceType:'', forWhom: '', medicalEducation: false, specialOccasion: [] },
    // { id: 9997, title: 'Сертификат спа', price: 1', duration: '', imgClass: 'card__img-search-3', serviceType:'', forWhom: '', medicalEducation: false, specialOccasion: [] },
    // { id: 9996, title: 'Подарочный сертификат онлайн ', price: 1', duration: '', imgClass: 'card__img-search-4', serviceType:'', forWhom: '', medicalEducation: false, specialOccasion: [] },
    // { id: 9995, title: 'Подарочный сертификат на массаж', price: 1', duration: '', imgClass: 'card__img-search-5', serviceType:'', forWhom: '', medicalEducation: false, specialOccasion: [] },
    // { id: 9994, title: 'Курс массажа', price: 2', duration: '', imgClass: 'card__img-search-6', serviceType:'Курсы и обучение', forWhom: '', medicalEducation: true, specialOccasion: ['8 марта', 'Новый год'] },
    // { id: 9993, title: 'Курс косметологии', price: 2',course: 'Курсы', duration: '', imgClass: 'card__img-search-7', serviceType:'Курсы и обучение', forWhom: '', medicalEducation: false, specialOccasion: ['14 февраля', 'День Рождение'] },


    // { id: '#', title: 'Сертификат ', price: 1', duration: '', imgClass: 'card__img-search-1', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Сертификат на массаж', price: 1p', duration: '', imgClass: 'card__img-search-2', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Сертификат спа', price: 1', duration: '', imgClass: 'card__img-search-3', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Подарочный сертификат онлайн ', price: 1', duration: '', imgClass: 'card__img-search-4', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Подарочный сертификат на массаж', price: 1', duration: '', imgClass: 'card__img-search-5', serviceType:'', forWhom: ''},
    { id: 6, title: 'Подарочный сертификат для женщины ', price: 3000, imgClass: 'card__img-search-6', serviceType:'Подарочный сертификат', forWhom: 'Для женщин'},
    { id: 7, title: 'Подарочный сертификат для мужчины', price: 3000, imgClass: 'card__img-search-7', serviceType:'Подарочный сертификат', forWhom: 'Для мужчин'},
    // { id: '#', title: 'Подарочный сертификат мужчине', price: 1', duration: '', imgClass: 'card__img-search-8', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Подарочный сертификат женщине', price: 1', duration: '', imgClass: 'card__img-search-9', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Подарочный сертификат родителям ', price: 1', duration: '', imgClass: 'card__img-search-10', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Подарочный сертификат на день рождения ', price: 1', duration: '', imgClass: 'card__img-search-11', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Подарочный сертификат на 8 марта', price: 1', duration: '', imgClass: 'card__img-search-12', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Подарочный сертификат на 23 февраля', price: 1', duration: '', imgClass: 'card__img-search-13', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Подарочный сертификат на новый год ', price: 1', duration: '', imgClass: 'card__img-search-14', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Сертификат на впечатление ', price: 1', duration: '', imgClass: 'card__img-search-15', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Электронный сертификат ', price: 1', duration: '', imgClass: 'card__img-search-16', serviceType:'', forWhom: ''},
    // { id: '#', title: 'Подарочный сертификат в Спа', price: 1', duration: '', imgClass: 'card__img-search-17', serviceType:'', forWhom: ''},








    // { id: '#', title: 'Антицеллюлитный массаж ', price: 1', duration: '', imgClass: 'card__img-search-49', serviceType:'', forWhom: ''},




    { id: 86, title: 'Спа массаж', price: 6000, duration: '1 час', imgClass: 'card__img-search-86', serviceType:'Спа', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 50, title: 'Курсы Классического массажа лица Заочно-дистанционно', price: 5000, duration: '18 ак.часа', imgClass: 'card__img-search-50', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 51, title: 'Курсы Классического массажа лица Очно в группе', price: 9000, duration: '18 ак.часа', imgClass: 'card__img-search-51', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 52, title: 'Курсы Классического массажа лица Очно-индивидуально', price: 18000, duration: '18 ак.часа', imgClass: 'card__img-search-52', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 53, title: 'курсы Антицеллюлитного массажа Заочно-дистанционно', price: 10000, duration: '72 ак.часа', imgClass: 'card__img-search-53', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 54, title: 'курсы Антицеллюлитного массажа Очно в группе', price: 18000, duration: '72 ак.часа', imgClass: 'card__img-search-54', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 55, title: 'курсы Антицеллюлитного массажа Очно-индивидуально ', price: 37000, duration: '72 ак.часа', imgClass: 'card__img-search-55', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 56, title: 'курсы Лимфодренажнолго массажа Заочно-дистанционно', price: 5000, duration: '18 ак.часа', imgClass: 'card__img-search-56', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 57, title: 'курсы Лимфодренажнолго массажа Очно в группе', price: 9000, duration: '18 ак.часа', imgClass: 'card__img-search-57', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 58, title: 'курсы Лимфодренажнолго массажа Очно-индивидуально', price: 18000, duration: '18 ак.часа', imgClass: 'card__img-search-58', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 59, title: 'курсы Спортивного массажа Заочно-дистанционно', price: 5000, duration: '18 ак.часа', imgClass: 'card__img-search-59', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 60, title: 'курсы Спортивного массажа Очно в группе', price: 9000, duration: '18 ак.часа', imgClass: 'card__img-search-60', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 61, title: 'курсы Спортивного массажа Очно-индивидуально', price: 18000, duration: '18 ак.часа', imgClass: 'card__img-search-61', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 62, title: 'курсы Медицинский массаж Очно в группе ', price: 49000, duration: '288 ак.часов', imgClass: 'card__img-search-62', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 63, title: 'курсы Медицинский массаж Очно-индивидуально ', price: 79000, duration: '288 ак.часов', imgClass: 'card__img-search-63', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},

    { id: 64, title: 'курсы Детский массаж Заочно-дистанционно', price: 10000, duration: '72 ак.часа', imgClass: 'card__img-search-64', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 65, title: 'курсы Детский массаж Очно в группе', price: 18000, duration: '72 ак.часа', imgClass: 'card__img-search-65', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 66, title: 'курсы Детский массаж Очно-индивидуально', price: 37000, duration: '72 ак.часа', imgClass: 'card__img-search-66', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 67, title: 'курсы Тайский массаж Заочно-дистанционно', price: 5000, duration: '16 ак.часа', imgClass: 'card__img-search-67', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 68, title: 'курсы Тайский массаж Очно в группе', price: 9000, duration: '16 ак.часа', imgClass: 'card__img-search-68', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 69, title: 'курсы Тайский массаж Очно-индивидуально', price: 18000, duration: '16 ак.часа', imgClass: 'card__img-search-69', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 70, title: 'курсы Буккальный массаж Заочно-дистанционно', price: 5000, duration: '18 ак.часа', imgClass: 'card__img-search-70', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 71, title: 'курсы Буккальный массаж Очно в группе', price: 9000, duration: '18 ак.часа', imgClass: 'card__img-search-71', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 72, title: 'курсы Буккальный массаж Очно-индивидуально ', price: 19000, duration: '18 ак.часа', imgClass: 'card__img-search-72', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 73, title: 'курсы LPG массаж Заочно-дистанционно', price: 5000, duration: '15 ак.часа', imgClass: 'card__img-search-73', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 74, title: 'курсы LPG массаж Очно в группе', price: 8000, duration: '15 ак.часа', imgClass: 'card__img-search-74', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 75, title: 'курсы LPG массаж Очно-индивидуально ', price: 17000, duration: '15 ак.часа', imgClass: 'card__img-search-75', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},

    { id: 76, title: 'курсы Мезотерапии Заочно-дистанционно', price: 5000, duration: '12 ак.часа', imgClass: 'card__img-search-76', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 77, title: 'курсы Мезотерапии Очно в группе', price: 9000, duration: '12 ак.часа', imgClass: 'card__img-search-77', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 78, title: 'курсы Мезотерапии Очно-индивидуально ', price: 15000, duration: '12 ак.часа', imgClass: 'card__img-search-78', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 79, title: 'курсы Инъекционные методы в косметологии Очно в группе', price: 35000, duration: ' 72 ак.часа', imgClass: 'card__img-search-79', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 80, title: 'курсы Инъекционные методы в косметологии Очно-индивидуально', price: 79000, duration: ' 72 ак.часа', imgClass: 'card__img-search-80', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 81, title: 'курсы пластического массажа лица Заочно-дистанционно', price: 5000, duration: '18 ак.часа', imgClass: 'card__img-search-81', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 82, title: 'курсы пластического массажа лица Очно в группе ', price: 9000, duration: '18 ак.часа', imgClass: 'card__img-search-82', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 83, title: 'курсы пластического массажа лица Очно-индивидуально ', price: 19000, duration: '18 ак.часа', imgClass: 'card__img-search-83', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 84, title: ' Сестринское дело в Косметологии Очно в группе', price: 57000, duration: '480 ак.часа', imgClass: 'card__img-search-84', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 85, title: ' Сестринское дело в Косметологии Очно-индивидуально', price: 89000, duration: '480 ак.часа', imgClass: 'card__img-search-85', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 886, title: 'курсы химический пилинг Заочно-дистанционно', price: 7000, duration: '32 ак.часа', imgClass: 'card__img-search-886', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 87, title: 'курсы химический пилинг Очно в группе', price: 15000, duration: '32 ак.часа', imgClass: 'card__img-search-87', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 88, title: 'курсы химический пилинг Очно-индивидуально', price: 29000, duration: '32 ак.часа', imgClass: 'card__img-search-88', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},

    { id: 89, title: 'курсы по пирсингу Очно в группе', price: 13000, duration: '15 ак.часа', imgClass: 'card__img-search-89', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 90, title: 'курсы по пирсингу Очно-индивидуально', price: 19000, duration: '15 ак.часа', imgClass: 'card__img-search-90', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 91, title: 'Косметолог-эстетист в Волгограде Заочно-дистанционно ', price: 15000, duration: '320 ак.часа', imgClass: 'card__img-search-91', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 92, title: 'Косметолог-эстетист в Волгограде Очно в группе', price: 75000, duration: '320 ак.часа', imgClass: 'card__img-search-92', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 93, title: 'Косметолог-эстетист в Волгограде Очно-индивидуально', price: 37500, duration: '320 ак.часа', imgClass: 'card__img-search-93', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 94, title: 'Обучение Перманентному макияжу Очно в группе', price: 35500, duration: '35 ак.часа', imgClass: 'card__img-search-94', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 95, title: 'Обучение Перманентному макияжу Очно-индивидуально', price: 75500, duration: '35 ак.часа', imgClass: 'card__img-search-95', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 96, title: 'Курс по пирсингу Очно в группе ', price: 13000, duration: '15 ак.часа', imgClass: 'card__img-search-96', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},
    { id: 97, title: 'Курс по пирсингу Очно-индивидуально ', price: 19000, duration: '15 ак.часа', imgClass: 'card__img-search-97', serviceType:'Курсы', forWhom: ['Для женщин','Для мужчин','Для двоих']},


    { id: 11154, title: "Массаж для лица",spis1:'Лимфодренажный массаж лица ',spis2:'Классический массаж лица',spis3:'Косметический массаж лица ',spis4:'Пластический(миоскульптурный) массаж лица',spis5:'Буккальный массаж лица',spis6:'Массаж лица по Жаке ',spis7:'Точечный массаж лица',spis8:'Японский массаж лица',spis9:'Стоунтерапия для лица',spis10:'Ультразвуковой массаж лица',spis11:'Вакуумный массаж лица', serviceType:'Массаж',forWhom: ['Для женщин','Для мужчин','Для двоих'] , desc: '', city: '' , imgClass: 'card__img-3 card__img-search-154'},



    { id: 806, title: "Массаж ребёнку", price: 500,spis1: 'Лечебный детский массаж от 1 месяца  ',duration:'30 мин.', spis2:'Профилактический детский массаж от 1 месяца', serviceType:'Массаж',forWhom: ['Для детей'],desc: '', city: '' , imgClass: 'card__img-3 card__img-search-201'},


    // Добавьте другие услуги здесь...
  ];
  const [modalVisible, setModalVisible] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const handleAddToCart = (product) => {
    console.log('Product added to cart:', product);
    setCartItems([...cartItems, product]);
  };
  const handleRemoveFromCart = (index) => {
    const newCartItems = cartItems.filter((item, i) => i !== index);
    setCartItems(newCartItems);
  };
  const [notification, setNotification] = useState({ show: false, message: '' });
  const handleAddToCartWithNotification = (product) => {
    handleAddToCart(product);
    setNotification({ show: true, message: `${product.title} добавлен в корзину` });
    setTimeout(() => {
      setNotification({ show: false, message: '' });
    }, 3000); // Hide notification after 3 seconds
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs.sendForm('service_9rthmgq', 'template_zxcsx6s', e.target, 'RFunDjpzDS4YPPTtu')
        .then((result) => {
          console.log(result.text);
          setModalVisible(false);
          alert('Ваш номер отправлен. Мы скоро перезвоним!');
        }, (error) => {
          console.log(error.text);
          alert('Произошла ошибка при отправке. Попробуйте еще раз.');
        });
  };


  return (
      <Router>
        <ScrollToTop />

        <div className='div-tel' onClick={() => setModalVisible(true)}>        <a className='telpage'></a></div>
        {notification.show && (
            <div className="notification">
              {notification.message}
            </div>
        )}

        {modalVisible && (
            <div className="modal">
              <div className="modal-content">
                <h2>Перезвоним за 28 секунд. Оставьте свой номер, и мы оперативно свяжемся с вами!</h2>
                <form onSubmit={handleSubmit}>
                  <input
                      type="tel"
                      name="phone_number"
                      placeholder="Введите ваш номер телефона (+7)"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                  />
                  <button type="submit">Отправить</button>
                  <button type="button" onClick={() => setModalVisible(false)}>Отмена</button>
                </form>
              </div>
            </div>
        )}
        <Header products={products}  onCityChange={handleCityChange} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} handleAddToCartWithNotification={handleAddToCartWithNotification} />
        <Breadcrumbs />
        <div className="App">
          <Routes>
            <Route path="/search" element={<SearchPage />} />
            <Route path="/" element={<HomePage products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/Home' element={<HomePage products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massage' element={<Massage products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massageTwo' element={<MassageTwo products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massageface' element={<Massageface products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city}handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massagechildren' element={<Massagechildren products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massageanti' element={<Massageanti products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/massagelpg' element={<Massagelpg products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/tatu' element={<Tatu products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/podarok' element={<Podarok products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/cosmet' element={<Cosmet products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/obuc' element={<Obuc products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/abon' element={<Abon products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/obuct' element={<Obuct products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/obucc' element={<Obucc products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}  titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path="/cart" element={<Cart  products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}/>} handleAddToCartWithNotification={handleAddToCartWithNotification}/>
            <Route path="/polyt" element={<Polyt products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart}/>} handleAddToCartWithNotification={handleAddToCartWithNotification}/>
            <Route path='/spatwo' element={<SpaTwo  products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/spa' element={<Spa products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path='/sotrud' element={<Sotrud products={products} cartItems={cartItems} handleAddToCart={handleAddToCart} handleRemoveFromCart={handleRemoveFromCart} titlee={`${city}е`} title={city} handleAddToCartWithNotification={handleAddToCartWithNotification}/>} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </div>
        <Footer />
      </Router>
  );
}

export default App;
