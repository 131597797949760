import React from 'react';
import './massage.css'
const products = [
    { id: 555, title: "Абонемент 1 месяц", price: 35000, serviceType:'Абонемент',forWhom: ['Для женщин','Для мужчин','Для двоих'],desc: '', city: '' , imgClass: 'card__img-3 card__img-search-555'},
    { id: 556, title: "Абонемент 3 месяца", price: 75000,serviceType:'Абонемент',forWhom: ['Для женщин','Для мужчин','Для двоих'], desc: '', city: '' , imgClass: 'card__img-3 card__img-search-555'},
    { id: 557, title: "Абонемент 1 год", serviceType:'Абонемент',forWhom: ['Для женщин','Для мужчин','Для двоих'],price: 90000, desc: '', city: '' , imgClass: 'card__img-3 card__img-search-555'},
];

const abon = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart, handleAddToCartWithNotification}) => {
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Абонементы на массаж в спа салоны {titlee}а</h1>

                <h2>
                Здесь представлен обзор цен и актуальная стоимость абонементa в {titlee} на различный период времени для регулярного посещения массажа и spa со скидкой 50% .
                <br />
                У нас возможно записаться на гостевой визит для знакомства с мастерами и приобрести абонемент на регулярное посещение себе или в подарок.
                </h2>
            </main>

            <main className="thride-block">
                <h1>Услуги массажа в {titlee}</h1>
                <h2>Расслабление и обновление вашего тела</h2>

                <div className="thride-block__cards sss">
                    {products.map((product) => (
                        <div key={product.id} className="thride-block__card2">
                            <div className={product.imgClass} style={{flex:'inherit'}}></div>
                            <h1 className="card__title">{product.title}</h1>
                            <h2 className="card__subtitle">{product.desc}</h2>
                            <h2 className="card__subtitle">Цена - {product.price}</h2>
                            <h2 className="card__subtitle">{product.city}</h2>
                            <button className="card__btn" onClick={() => handleAddToCartWithNotification(product)}>Заказать</button>
                        </div>

// onClick={() => handleAddToCartWithNotification(product)}
                    ))}
                </div>
            </main>

            <main className="middle-block">
                <div className="title-block-mas">
                    <h1>Правила</h1>
                    <h2>Правила использования абонемента <br />
Безусловным принятием условий настоящих правил является осуществление Заказчиком платежа в счет оплаты услуг и получение соответствующего финансового документа, подтверждающего факт оплаты.</h2>
                </div>
            <div className="hg">
                <div className="sv">
                <p>1</p>
                </div>

                <div>
                    <h5>Первое правило</h5>
                    <h6>Стоимость абонемента (АБ) <strong>является предоплатой за услуги</strong>  и предоставляет его <strong>владельцу скидку 50%</strong> в течении срока действия.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <p>2</p>
                </div>
                <div>
                    <h5>Второе правило</h5>
                    <h6>Абонемент является именным и действителен только для его владельца.
                    Владелец абонемента может <strong>переоформить</strong> свой абонемент на другого человека, условия переоформления уточняйте у менеджера.
                    </h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <p>3</p>
                </div>
                <div>
                    <h5>Третье правило</h5>
                    <h6><strong>В случае опоздания</strong> посетителя более чем на 15 минут, время посещение уменьшается на время опоздания.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <p>4</p>
                </div>
                <div>
                    <h5>Четвертое правило</h5>
                    <h6><strong>Если по каким-то причинам Вы не можете посетить салон в запланированное время, просим отменять визит не менее чем за 3 часа. Несвоевременный отказ от забронированного времени прохождения процедур по абонементу ведет к списанию эквивалента суммы планируемого визита.</strong></h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <p>5</p>
                </div>
                <div>
                    <h5>Пятое правило</h5>
                    <h6><strong>Бронирование времени для прохождения процедур по АБ происходит по предварительной записи по номеру +7-996-509-10-11 или заявке на сайте. При предварительной записи будьте готовы сообщить номер абонемента.
</strong></h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <p>6</p>
                </div>
                <div>
                    <h5>Шестое правило</h5>
                    <h6><strong>Абонементы не подлежат возврату и обмену на денежные средства, а также размену на АБ меньшего номинала.
</strong></h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <p>7</p>
                </div>
                <div>
                    <h5>Седьмое правило</h5>
                    <h6><strong>АБ является подтверждением внесения авансового платежа и предоставляет владельцу право пользования услугами в СПА и салонах красоты на сумму и срок действия, эквивалентную номиналу абонемента в ассортименте и в соответствии с внутренними нормами spa-этикета.
</strong></h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <p>8</p>
                </div>
                <div>
                    <h5>Восьмое правило</h5>
                    <h6><strong>АБ обслуживается по ценам принимающего салона. В случае, если он был куплен в салоне с меньшим тарифом, может потребоваться доплата.
</strong></h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <p>9</p>
                </div>
                <div>
                    <h5>Девятое правило</h5>
                    <h6><strong>АБ распространяется только на оплату услуг, не действует при оплате косметики и сопутствующих товаров.
</strong></h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <p>10</p>
                </div>
                <div>
                    <h5>Десятое правило</h5>
                    <h6><strong>АБ действителен год с даты продажи, которая указывается на бланке абонемента или вкладыше к нему.
</strong></h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <p>11</p>
                </div>
                <div>
                    <h5>Одинадцатое правило</h5>
                    <h6><strong>При использовании АБ в качестве средства оплаты в период его действия другие дисконтно-накопительные программы могут быть недействительны.
</strong></h6>
                </div>
            </div>
            </main>
        </div>
    );
}

export default abon