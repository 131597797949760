import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';

const Breadcrumbs = ({products}) => {
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  const breadcrumbsMap = {
    'about-me': 'О салоне',
    'features': 'Массаж',
    'cases': 'Косметология',
    'contact': 'Спа',
    'tatu': 'Тату',
    'obuc': 'Обучение массажу',
    'obucc': 'Обучение косметологии',
    'obuct': 'Обучение тату и татуажу',
    'podarok': 'Подарочные сертификаты',
    'massage': 'Массаж ',
    'massageface': 'Массаж для лица',
    'massageTwo': 'Массаж для двоих',
    'massagechildren': 'Детский массаж',
    'massageanti': 'Антицеллюлитный массаж',
    'massagelpg': 'LPG массаж',
    'abon': 'Абонементы',
    'cosmet': 'Косметология',
    'cart' : 'Корзина',
    'polyt' : 'Политика конфиденциальности',
    'spa' : 'Спа',
    'spatwo' : 'Спа для двоих',
    'search' : 'Поиск',
    'sotrud' : 'Сотрудничество'
    // Добавьте другие переводы маршрутов здесь
  };

  return (
    <nav className="breadcrumbs">
      <ul>
        <li>
          <NavLink to="/">Главная</NavLink>
        </li>
        {pathnames.map((value, index) => {
          const to = `/${pathnames.slice(0, index + 1).join('/')}`;
          const breadcrumbLabel = breadcrumbsMap[value] || value;

          return (
            <li key={to}>
              <NavLink to={to}>{breadcrumbLabel}</NavLink>
            </li>
          );
        })}
      </ul>
    </nav>
  );
};

export default Breadcrumbs;