import React, { useState } from 'react';
import img from "../../image/HomePage/logonew.jpg";
import { NavLink, useNavigate } from 'react-router-dom';



const Footer = () => {
    const [showModal, setShowModal] = useState(false);

    const handleInstagramClick = (event) => {
        event.preventDefault(); // Останавливает стандартное действие ссылки
        setShowModal(true); // Показывает модальное окно
    };

    const handleModalClose = () => {
        setShowModal(false);
        window.location.href = 'https://www.instagram.com/alex_estetica';
    };

    return (
        <div className="t981">
            <div className="t981__wrapper t-container">
                <div className="t981__col t981__col_left">
                    <div className="t981__logo-wrapper">
                        <img
                            src={img}
                            className="t981__logo t-img"
                            style={{ maxWidth: '170px' }}
                            alt="СПА академия-Нижний Новгород"
                        />
                    </div>
                </div>
                <div className="t981__col t981__col_right" style={{ width: 'auto' }}>
                    <div className="t981__menu t981__main-menu" style={{ justifyContent: 'flex-start', gap: '24px' }}>
                        <NavLink to="/"><MenuItem text="О Нас" /></NavLink>
                        <NavLink to="/abon"><MenuItem text="Абонементы" /></NavLink>
                        <NavLink to="/massage"><MenuItem text="Массаж" /></NavLink>
                        <NavLink to="/cosmet"><MenuItem text="Косметология" /></NavLink>
                        <NavLink to="/spa"><MenuItem text="Спа" /></NavLink>
                        <NavLink to="/tatu"><MenuItem text="Тату" /></NavLink>
                        <NavLink to="/obuc"><MenuItem text="Обучение массажу" /></NavLink>
                        <NavLink to="/obucc"><MenuItem text="Обучение косметологии" /></NavLink>
                        <NavLink to="/obuct"><MenuItem text="Обучение Тату" /></NavLink>
                        <NavLink to="/podarok"><MenuItem text="Подарочные сертификаты" /></NavLink>
                        <NavLink to="/sotrud"><MenuItem text="Сотрудничество" /></NavLink>
                    </div>
                    <div className="t981__menu t981__secondary-menu" style={{ color: '#171717', justifyContent: 'flex-start' }}>
                        <ul>
                            <li><strong>© 2024 Академия Спа Адлер</strong></li>
                            <li><strong>Политика </strong><a href="konfid" rel="noopener noreferrer"><strong>конфиденциальности</strong></a></li>
                            <li><strong>Почта:</strong><a href="mailto:spa_academy@mail.ru" rel="noopener noreferrer"><strong>spa_academy@mail.ru</strong></a></li>
                        </ul>
                    </div>
                </div>
            </div>
            <SocialLinks handleInstagramClick={handleInstagramClick} />
            {showModal && (
                <div className="modal">
                    <div className="modal-content">
                        <p style={{marginTop: '16px'}}>Внимание! Instagram запрещен на территории Российской Федерации. Вы будете перенаправлены на Instagram.</p>
                        <button onClick={handleModalClose}>Перейти</button>
                        <button onClick={() => setShowModal(false)}>Закрыть</button>
                    </div>
                </div>
            )}
            <RedConnect />
        </div>
    );
};

const MenuItem = ({ href, text }) => (
    <div className="t981__menu-item">
        <a className="t981__menu-link" style={{ color: '#000000', textTransform: 'uppercase' }} href={href} data-menu-submenu-hook="">{text}</a>
    </div>
);

const SocialLinks = ({ handleInstagramClick }) => (
    <div id="rec328547330" className="r t-rec t-rec_pt_0 t-rec_pb_15" style={{ paddingTop: '0px', paddingBottom: '15px' }} data-record-type="212">
        <div className="t188">
            <div className="t-container_100">
                <div className="t188__wrapone">
                    <div className="t188__wraptwo">
                        <SocialLink href="https://vk.com/kursy_sochi" title="VK" svgPath="M47.761,24c0,13.121-10.639,23.76-23.76,23.76C10.878,47.76,0.239,37.121,0.239,24c0-13.123,10.639-23.76,23.762-23.76C37.122,0.24,47.761,10.877,47.761,24 M35.259,28.999c-2.621-2.433-2.271-2.041,0.89-6.25c1.923-2.562,2.696-4.126,2.45-4.796c-0.227-0.639-1.64-0.469-1.64-0.469l-4.71,0.029c0,0-0.351-0.048-0.609,0.106c-0.249,0.151-0.414,0.505-0.414,0.505s-0.742,1.982-1.734,3.669c-2.094,3.559-2.935,3.747-3.277,3.524c-0.796-0.516-0.597-2.068-0.597-3.171c0-3.449,0.522-4.887-1.02-5.259c-0.511-0.124-0.887-0.205-2.195-0.219c-1.678-0.016-3.101,0.007-3.904,0.398c-0.536,0.263-0.949,0.847-0.697,0.88c0.31,0.041,1.016,0.192,1.388,0.699c0.484,0.656,0.464,2.131,0.464,2.131s0.282,4.056-0.646,4.561c-0.632,0.347-1.503-0.36-3.37-3.588c-0.958-1.652-1.68-3.481-1.68-3.481s-0.14-0.344-0.392-0.527c-0.299-0.222-0.722-0.298-0.722-0.298l-4.469,0.018c0,0-0.674-0.003-0.919,0.289c-0.219,0.259-0.018,0.752-0.018,0.752s3.499,8.104,7.573,12.23c3.638,3.784,7.764,3.36,7.764,3.36h1.867c0,0,0.566,0.113,0.854-0.189c0.265-0.288,0.256-0.646,0.256-0.646s-0.034-2.512,1.129-2.883c1.15-0.36,2.624,2.429,4.188,3.497c1.182,0.812,2.079,0.633,2.079,0.633l4.181-0.056c0,0,2.186-0.136,1.149-1.858C38.281,32.451,37.763,31.321,35.259,28.999" />
                        <a
                            href="https://www.instagram.com/alex_estetica"
                            title="Instagram"
                            onClick={handleInstagramClick}
                            style={{ width: 48, height: 48 }}
                        >
                            <img
                                src="../instagram-logo-facebook-2-svgrepo-com.svg"
                                alt="Instagram Logo"
                                style={{ width: 48, height: 48 }}
                            />
                        </a>
                    </div>        
                </div>
            </div>
        </div>
    </div>
);

const SocialLink = ({ href, title, svgPath }) => (
    <div className="t188__sociallinkimg">
        <a href={href} target="_blank" rel="nofollow">
            <svg className="t-sociallinks__svg" version="1.1" xmlns="http://www.w3.org/2000/svg"  width="48px" height="48px" viewBox="0 0 48 48" enableBackground="new 0 0 48 48" xmlSpace="preserve">
                <desc>{title}</desc>
                <path  d={svgPath} />
            </svg>
        </a>
    </div>
);

const RedConnect = () => (
    <>
        <script id="rhlpscrtg" type="text/javascript" charset="utf-8" async src="https://web.redhelper.ru/service/main.js?c=fadieiev1988"></script>
        <div style={{ display: 'none' }}>
            <a className="rc-copyright" href="http://redconnect.ru">Обратный звонок RedConnect</a>
        </div>
    </>
);

export default Footer;
