import React from 'react';

import './massage.css'

const products = [
    { id: 918, title: "Подарочные сертификаты на СПА программы для двоих в Волгограде", price: 6000, desc: 'Цена зависит от города и выбранной услуги.',serviceType:'Спа',forWhom: ['Для двоих'], city: '' , imgClass: 'card__img-3 img-918'},
    { id: 919, title: "Подарочные сертификаты для мужчины в Волгограде", price: 3000, desc: 'Цена зависит от города и выбранной услуги.',serviceType:'Спа',forWhom: ['Для мужчин'], city: '' , imgClass: 'card__img-3 img-919'},
    { id: 920, title: "Подарочные сертификаты для девушки или женщине в Волгограде", price: 3000, desc: 'Цена зависит от города и выбранной услуги.',serviceType:'Спа',forWhom: ['Для женщин'], city: '' , imgClass: 'card__img-3 img-920'},
];

const sertificat = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart, handleAddToCartWithNotification}) => {




    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Подарочные сертификаты на массаж в Спа-салоны {titlee}</h1>

                <h2>
                Подарочные сертификаты на массаж в Спа салоны Волгограда являются оригинальным подарком, который подойдет как женщине, так и мужчине.
<br />
Его по достоинству оценит получатель любого возраста и социального положения.
<br />
Выбирайте подходящий дизайн подарочного сертификата, который передаст настроение праздника его получателю. Ваш стильный и необычный подарок на приятные, запоминающиеся впечатления несомненно понравится!
<br />
Вы можете выбрать сертификат с номиналом на любую подходящую сумму или оставить ее в тайне, выбрав в качестве подарка услугу из широкого ассортимента массажа и спа-процедур, представленных на сайте.
                </h2>
            </main>

            <main className="thride-block">
                <h1>Подарочные сертификаты на массаж в спа и салоны красоты в {titlee}</h1>
                <h2>Расслабление и обновление вашего тела</h2>

                <div className="thride-block__cards">
                    {products.map((product) => (
                        <div key={product.id} className="thride-block__card2 ososo">
                            <div className={product.imgClass} style={{flex:'inherit'}}></div>
                            <div>                            <h1 className="card__title">{product.title}</h1>
                                <h2 className="card__subtitle">{product.desc}</h2>
                                <h2 className="card__subtitle">Цена - {product.price}</h2>
                                <h2 className="card__subtitle">{product.city}</h2>
                                <button className="card__btn" onClick={() => handleAddToCartWithNotification(product)}>Добавить в корзину</button></div>
                        </div>
                    ))}
                </div>
            </main>
            <main className="main-block">
                <h1>Электронный сертификат в {titlee}</h1>

                <h2>
                    Доставка электронных подарочных сертификатов в Волгограде осуществляется в течение часа после оплаты, без ожидания курьера и дополнительных расходов.
                        <br />
                    Такой сертификат одинаково удобно подарить своему другу или любимому человеку в Волгограде, или другом городе, просто переслав его по электронной почте. Вы можете выбрать электронный сертификат на любую сумму или услугу из представленных на нашем официальном сайте.
                </h2>

                <h1>Сертификат с доставкой курьером в {titlee}</h1>

                <h2>
                    Перед тем, как оформить сертификат с доставкой, вы можете выбрать подходящий дизайн. Также, как и электронный, он может быть на любую сумму(номинал) или представленную на сайте услугу.
                        <br />
                    Доставка сертификата на данный момент возможна по Волгограду и Волгоградской области.
                </h2>

                <h1>Правила использования сертификата в {titlee}</h1>

                <ul className='list'>
                    <li>Подарочный сертификат (ПС) действителен год с даты продажи, которая указывается на бланке подарочного сертификата или вкладыше к нему.</li>
                    <li>Бронирование времени для прохождения процедур по Подарочному сертификату происходит по предварительной записи по номеру +7-996-509-10-11 или заявке на сайте. При предварительной записи будьте готовы сообщить номер подарочного сертификата.</li>
                    <li>Подарочные сертификаты не подлежат возврату и обмену на денежные средства, а также размену на ПС меньшего номинала.</li>
                    <li>Если стоимость выбранной услуги ниже номинала сертификата, то разница в денежном эквиваленте не компенсируется, а засчитывается при последующих визитах. Если цена услуги выше номинала предъявляемого ПС, то возникающая разница в стоимости оплачивается предъявителем ПС согласно действующему прайсу.</li>
                    <li>Если по каким-то причинам Вы не можете посетить салон в запланированное время, просим отменять визит не менее чем за 3 часа. Несвоевременный отказ от забронированного времени прохождения процедур по подарочному сертификату ведет к списанию эквивалента суммы планируемого визита.</li>
                    <li>Подарочный сертификат является подтверждением внесения авансового платежа и предоставляет владельцу право пользования услугами в СПА и салонах красоты на сумму, эквивалентную номиналу подарочного сертификата в ассортименте и в соответствии с внутренними нормами spa-этикета.</li>
                    <li>Услуги салона оказываются только при предъявлении подарочного сертификата, распечатанного электронного сертификата или скриншота электронного сертификата.</li>
                    <li>ПС можно передавать любым лицам по своему усмотрению. При передаче ПС третьим лицам владелец ПС обязан проинформировать лиц, получающих ПС, об условиях получения по ним товаров/услуг. В случае нарушения этой обязанности владельцами ПС Академии Спа по претензиям, связанным с отсутствием вышеуказанной информации, ответственности не несет.</li>
                    <li>При использовании ПС в качестве средства оплаты другие дисконтно-накопительные программы могут быть недействительны.</li>
                    <li>Одним ПС можно оплатить несколько товаров/услуг в рамках одной покупки/транзакции.</li>
                    <li>ПС обслуживается по ценам принимающего салона. В случае, если сертификат на услугу был куплен в салоне с меньшим тарифом, может потребоваться доплата.</li>
                    <li>ПС распространяется только на оплату услуг, не действует при оплате абонементов и косметики.</li>
                    <li>ПС не подлежит обмену или возврату. ПС не подлежит восстановлению в случае утери, кражи или порчи.</li>
                    <li>ПС необходимо предъявить до начала обслуживания в салоне.</li>
                    <li>Дополнительную информацию можно получить по номеру телефона:+7-996-509-10-11.</li>
                </ul>

                <h2>
                    Безусловным принятием условий настоящих правил является осуществление Заказчиком платежа в счет оплаты услуг и получение соответствующего финансового документа, подтверждающего факт оплаты.
                </h2>
            </main>
        </div>
    );
}

export default sertificat