import React, { useState } from 'react';
import './sotrud.css';
import { MdPeopleAlt } from "react-icons/md";
import emailjs from "emailjs-com";

const courses = [
    {
      title: 'Курсы тату-базовый(Профи)',
      content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Изучение стилей — их специфики, особенностей и отличий;</li>
            <li>Санитарные требования к кабинету, организация рабочего места;</li>
            </ul>
        </>
      )
    },
    { title: 'Курс Мини-тату',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>Виды оборудования, санитарные требования, стерилизация;</li>
            <li>Организация рабочего места;</li>
          </ul>
        </>
      ) },
    { title: 'Обучение Перманентному макияжу',
    content: (
        <>
          <p><strong>Программа курса:</strong></p>
          <p><strong>Теоретическая часть:</strong></p>
          <ul>
            <li>История и суть перманентного макияжа;</li>
            <li>показания противопоказания;</li>
            </ul>
        </>
      ) },

  ];
const Accordion = ({ title, content }) => {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="accordion-item">
        <div className="accordion-title" onClick={() => setIsOpen(!isOpen)}>
          <h3>{title}</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <p>{content}</p>
          </div>
        )}
      </div>
    );
  };
const handleFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const templateParams = {
        name: formData.get('name'),
        tel: formData.get('tel'),
        cartDetails: formData.get('cartDetails'),
    };

    emailjs.send('service_9rthmgq', 'template_zxcsx6s', templateParams, 'RFunDjpzDS4YPPTtu')
        .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
            alert('Заявка успешно отправлена!');
        }, (error) => {
            console.error('FAILED...', error);
            alert('Произошла ошибка при отправке заявки.');
        });
};

const sotrud = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart}) => {
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>Подключение новых партнеров</h1>

                <h2>
                    Сколько зарабатывают партнеры ---?
                </h2>

                <div className='st-bl'>
                    <div className='c-cr'>
                        <div className='c-img'></div>
                        <h3>Название</h3>
                        <h2>СТОИМОСТЬ руб/мес</h2>
                    </div>

                    <div className='c-cr'>
                        <div className='c-img'></div>
                        <h3>Название</h3>
                        <h2>СТОИМОСТЬ руб/мес</h2>
                    </div>

                    <div className='c-cr'>
                        <div className='c-img'></div>
                        <h3>Название</h3>
                        <h2>СТОИМОСТЬ руб/мес</h2>
                    </div>
                </div>
            </main>

            <main className='part-kto'>
                <h1>Кто может стать нашим партнером?</h1>

                <div className="kto-bl">
                    <div className="kto-cr">
                        <h2>Работа по безналу <br/> <p>поступления на рассчетный счет</p></h2>
                    </div>
                    <div className="kto-cr">
                        <h2>Работа по безналу <br/> <p>поступления на рассчетный счет</p></h2>
                    </div>
                    <div className="kto-cr">
                        <h2>Работа по безналу <br/> <p>поступления на рассчетный счет</p></h2>
                    </div>
                    <div className="kto-cr">
                        <h2>Работа по безналу <br/> <p>поступления на рассчетный счет</p></h2>
                    </div>
                </div>
            </main>

            <div className="prt-bl-img"></div>

            <a href="#form-part" className="reg-part"><MdPeopleAlt /> Регистрация партнера</a>

            <div className="accord-block">
                <h1 style={{marginBottom:"24px"}}>Откуда клиенты?</h1>

                {courses.map((course, index) => (
                    <Accordion key={index} title={course.title} content={course.content} />
                ))}
            </div>

            <div className="rabot-shag">
                <h1>Начало работы по шагам:</h1>

                <div>
                    <div>
                        <div className="nac-img img-one">1</div>

                        <h2>Регистрация</h2>
                    </div>

                    <div>
                        <div className="nac-img img-two">2</div>

                        <h2>Подписание договора</h2>
                    </div>

                    <div>
                        <div className="nac-img img-three">3</div>

                        <h2>Добавление услуг</h2>
                    </div>
                </div>
            </div>

            <div className="div-mid">
                Стать партнером --- - легко. <br/> Увеличивай свою прибыль с нами!
            </div>

            <form action="" className="part" id="form-part" onSubmit={handleFormSubmit}>
                <h1>Стать партнером</h1>

                <h2><p>*</p> Название компании</h2>
                <input type="text" name="" id=""  required/>
                <h2><p>*</p> Сфера деятельности компании
                </h2>
                <input type="text" name="" id=""  required/>
                <h2>Ваше конкурентное преимущество

                </h2>
                <input type="text" name="" id="" />
                <h2><p>*</p> Сайт компании

                </h2>
                <input type="text" name="" id="" required/>
                <h2>Наименование юр. лица

                </h2>
                <input type="text" name="" id="" />
                <h2><p>*</p> Город оказания услуг

                </h2>
                <input type="text" name="" id="" required/>
                <h2>Ссылки на соцсети

                </h2>
                <input type="text" name="" id="" />
                <h2>Ссылки на отзывы

                </h2>
                <input type="text" name="" id="" />
                <h2>Опыт работы

                </h2>
                <input type="text" name="" id=""  required/>
                <h2><p>*</p> Контактный номер телефона

                </h2>
                <input type="tel" name="" id="" required/>
                <h2><p>*</p> Почта</h2>
                <input type="Email" name="" id="" required/>
                <h2>ФИО контактного лица

                </h2>
                <input type="text" name="" id="" />
                <h2>Промокод</h2>
                <input type="text" name="" id="" />

                <button type={"submit"}>Отправить</button>
            </form>

            <main className="middle-block">
                <div className="title-block-mas">
                    <h1>4 причины выбрать ---</h1>
                </div>
                <div className="hg">
                    <div className="sv" style={{marginRight: "24px"}}>
                    <p>1</p>
                    </div>

                    <div >
                        <h5>Персональные наборы
                        </h5>
                        <h6>Редактируйте уже готовые подарочные наборы или создавайте полностью свои списки впечатлений</h6>
                    </div>
                </div>
                <div className="hg">
                <div className="sv" style={{marginRight: "24px"}}>
                    <p>2</p>
                    </div>
                    <div >
                        <h5>Моментальный обмен
                        </h5>
                        <h6>Получатель подарка может заменить услугу на сайте на любое другое впечатление в пределах номинала сертификата.
                        </h6>
                    </div>
                </div>
                <div className="hg">
                    <div className="sv" style={{marginRight: "24px"}}>
                        <p>3</p>
                    </div>
                    <div>
                        <h5>2-й сертификат БЕСПЛАТНО</h5>
                        <h6>Получите сертификат "Набор X" в подарок при заказе от 5000 руб.</h6>
                    </div>
                </div>
                <div className="hg">
                    <div className="sv" style={{marginRight: "24px"}}>
                        <p>4</p>
                    </div>
                    <div>
                        <h5>Срок действия сертификата 12 месяцев</h5>
                        <h6>Позволяет воспользоваться сертификатом в течение 365 дней с момента оформления</h6>
                    </div>
                </div>
            </main> 
        </div>
    );
}

export default sotrud