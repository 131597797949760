// HomePage.js
import React from 'react';
import './Main.css'
import Slider from 'react-slick';
import {NavLink} from "react-router-dom";

const reviews = [
    {
        name: "Ксения",
        date: "17.05.2023",
        rating: "отлично",
        advantages: "Расслабляет",
        disadvantages: "Хочется еще",
        comment: "Решила первый раз пойти на масляный массаж. Зря не попробовала это чудо раньше...",
        adminResponse: "Спасибо за Ваш отзыв"
    },
    {
        name: "Ксения2",
        date: "17.05.2023",
        rating: "отлично",
        advantages: "Расслабляет",
        disadvantages: "Хочется еще",
        comment: "Решила первый раз пойти на масляный массаж. Зря не попробовала это чудо раньше...",
        adminResponse: "Спасибо за Ваш отзыв"
    },
    // Добавьте другие отзывы сюда
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            ›
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            ‹
        </div>
    );
};




const HomePage = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart}) => {
    
    // Обновите настройки слайдера
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
  return (
    <div>
        <main className="img-main">
                <h1>СПА-МАССАЖ И ОБУЧЕНИЕ В {titlee}</h1>
                <h2>Добро пожаловать на официальный сайт Академии Спа! Мы стремимся развивать качественные спа услуги и обучение специалистов в {titlee}, массаж и фитнес, Spa и косметология, совмещая приятное с полезным!</h2>
        </main>

        <NavLink to="/massage" className="btn-block">
            Записаться →
        </NavLink>

        <main className="second-block">
            <h1>Массаж и обучение в {titlee}</h1>
                <p>
                    В разделе ☞ <a href="">spa-программы в {titlee}</a> Вы найдете описание предлагаемых спа процедур с фото, чтобы выбрать и заказать лучшие, сравнить их цены и уточнить удобные адреса, где делают хороший массаж в спа. Профессионализм мастеров и приятные расслабляющие программы подарят вам истинное наслаждение!        
                </p>
                <p>
                    <a href="">☞Заказать подарочный сертификат в {titlee}</a> к мастеру спа или косметологу, на массаж для близких и друзей, а также натуральную и профессиональную косметику для мужчин и женщин. 
                </p>


                <p>
                    <a href="">☞Узнать про абонемент в {titlee}</a> для регулярного посещения услуг со скидкой до 50%.
                </p>

        </main>

        <main className="thride-block">
            <h1>Спа салоны и цены в {titlee}</h1>
            <h2>Расслабление и обновление вашего тела</h2>

            <div className="thride-block__cards">
                <div className="thride-block__card ososo">
                    <div className="card__img-1" style={{flex:'inherit'}}></div>

                    <h1 className="card__title">Косметология</h1>
                    <h2 className="card__subtitle">Выберите свою процедуру или подберите подходящую с косметологом.</h2>
                    <h2 className="card__subtitle">Цена — от 1000₽</h2>
                    <h2 className="card__subtitle">Место — {title}</h2>

                    <NavLink to="/cosmet" className="card__btn">Подробнее</NavLink>
                </div>
                <div className="thride-block__card ososo">
                    <div className="card__img-2" style={{flex:'inherit'}}></div>

                    <h1 className="card__title">Массаж</h1>
                    <h2 className="card__subtitle">Выберите свой массаж.</h2>
                    <h2 className="card__subtitle">Цена — от 1000₽</h2>
                    <h2 className="card__subtitle">Место — {title}</h2>

                    <NavLink to="/massage" className="card__btn">Подробнее</NavLink>
                </div>
                <div className="thride-block__card ososo">
                    <div className="card__img-3" style={{flex:'inherit'}}></div>

                    <h1 className="card__title">СПА программы на выбор</h1>
                    <h2 className="card__subtitle">Выберите свой Спа-отдых.</h2>
                    <h2 className="card__subtitle">Цена — от 3000₽</h2>
                    <h2 className="card__subtitle">Место — {title}</h2>

                    <NavLink to="/spa" className="card__btn">Подробнее</NavLink>
                </div>
                <div className="thride-block__card ososo">
                    <div className="card__img-4" style={{flex:'inherit'}}></div>

                    <h1 className="card__title">Обучение массажу</h1>
                    <h2 className="card__subtitle">Обучение и повышение квалификации для начинающих и опытных специалистов.</h2>
                    <h2 className="card__subtitle">Цена — от 5000₽</h2>
                    <h2 className="card__subtitle">Место — {title}</h2>

                    <NavLink to="/obuc" className="card__btn">Подробнее</NavLink>
                </div>
                <div className="thride-block__card center">
                    <div className="card__img-5" style={{flex:'inherit'}}></div>

                    <h1 className="card__title">Обучение в косметологии</h1>
                    <h2 className="card__subtitle">Обучение и повышение квалификации для начинающих и опытных специалистов.</h2>
                    <h2 className="card__subtitle">Цена — от 5000₽</h2>
                    <h2 className="card__subtitle">Место — {title}</h2>

                    <NavLink to="/obucc" className="card__btn">Подробнее</NavLink>
                </div>
            </div>
        </main>

        <main className="middle-block">
            <div className="hg">
                <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>

                <div>
                    <h5>Высокое качество</h5>
                    <h6>Наши специалисты постоянно повышают свою квалификацию и участвуют в международных конференциях.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>
                <div>
                    <h5>Чистота и комфорт</h5>
                    <h6>Для нас важно, чтобы клиенты чувствовали себя комфортно. Вот почему мы всегда следим за чистотой и атмосферой наших салонов.</h6>
                </div>
            </div>
            <div className="hg">
            <div className="sv">
                <svg class="svgg"  fill="#69b974" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                <path d="M50.1 98.9c-26.7 0-48.5-21.8-48.5-48.5S23.4 1.9 50.1 1.9c8.9 0 17.5 2.4 25.1 7 .7.4.9 1.4.5 2.1-.4.7-1.4.9-2.1.5C66.5 7.2 58.4 5 50.1 5 25 4.9 4.6 25.3 4.6 50.4S25 95.9 50.1 95.9s45.5-20.4 45.5-45.5c0-3.2-.3-6.4-1-9.5-.2-.8.3-1.6 1.2-1.8.8-.2 1.6.3 1.8 1.2.7 3.3 1.1 6.7 1.1 10.1-.1 26.7-21.8 48.5-48.6 48.5z">
                <path d="M50.1 63.9c-.4 0-.8-.2-1.1-.4L24.8 39.2c-.6-.6-.6-1.5 0-2.1.6-.6 1.5-.6 2.1 0l23.2 23.2 46.8-48c.6-.6 1.5-.6 2.1 0 .6.6.6 1.5 0 2.1l-47.8 49c-.3.3-.7.5-1.1.5z">
                </path></path>
                </svg>
                </div>
                <div>
                    <h5>Индивидуальный подход</h5>
                    <h6>Наша компания работает по принципу индивидуального подхода к каждому клиенту. Мы уважаем ваши пожелания и предпочтения.</h6>
                </div>
            </div>
        </main>

        <main className="uslugi">
            <h1 style={{marginBottom: 24}}>Услуги</h1>

            <div className="uslugi-block__cards">
                    <div className="uslugi-block__row">
                        <NavLink to="/obucc" className="uslugi-block__card">
                            <div className="uslugi-card__img-1"></div>
                            <h1 className="card__title">Курсы косметологии</h1>
                        </NavLink>
                            <NavLink to="/obuc" className="uslugi-block__card">
                            <div className="uslugi-card__img-2"></div>
                            <h1 className="card__title">Курсы массажа</h1>
                        </NavLink>
                                <NavLink to="/massage" className="uslugi-block__card">
                            <div className="uslugi-card__img-3"></div>
                            <h1 className="card__title">Массаж</h1>
                        </NavLink>
                    </div>
                    <div className="uslugi-block__row">
                        <NavLink to="/cosmet" className="uslugi-block__card">
                            <div className="uslugi-card__img-4"></div>
                            <h1 className="card__title">Косметология</h1>
                        </NavLink>
                            <NavLink to="/tatu" className="uslugi-block__card">
                        <div className="uslugi-card__img-5"></div>
                        <h1 className="card__title">Тату</h1>
                        </NavLink>
                                <NavLink to="/abon" className="uslugi-block__card">
                        <div className="uslugi-card__img-6"></div>
                        <h1 className="card__title">Абонименты</h1>
                        </NavLink>
                    </div>
                    <div className="uslugi-block__row">
                        <NavLink to="/podarok" className="uslugi-block__card">
                        <div className="uslugi-card__img-7"></div>
                        <h1 className="card__title">Подарочные сертификаты</h1>
                        </NavLink>
                            <NavLink to="/spa" className="uslugi-block__card">
                        <div className="uslugi-card__img-8"></div>
                        <h1 className="card__title">SPA</h1>
                        </NavLink>
                                <NavLink to="/" className="uslugi-block__card">
                        <div className="uslugi-card__img-9"></div>
                        <h1 className="card__title">О нас</h1>
                        </NavLink>
                    </div>
            </div>
        </main>

        <div className="review-slider">
            <h1>Отзывы об услуге</h1>
            <Slider {...settings}>
                {reviews.map((review, index) => (
                    <div key={index} className="review">
                        <p><strong>{review.name} {review.date}</strong></p>
                        <p>{review.rating}</p>
                        <p><strong>Достоинства:</strong> {review.advantages}</p>
                        <p><strong>Недостатки:</strong> {review.disadvantages}</p>
                        <p><strong>Комментарий:</strong> {review.comment}</p>
                        <p><strong>Ответ администратора {review.date}</strong></p>
                        <p>{review.adminResponse}</p>
                    </div>
                ))}
            </Slider>
        </div>

        <main className='fo-block'>
            <h1>Специалисты</h1>
            <h2>Опытные профессионалы своего дела!</h2>

            <div className="spec">
                <div className="spec-card">
                    <div className="spec-img-1"></div>

                    <div className="spec-title">
                    Массаж— поистине это полезно и предотвращает от болезней органов 
                    </div>
                </div>

                <div className="spec-card">
                    <div className="spec-img-2"></div>

                    <div className="spec-title">
                    Эффект массажа — естественная восстанавливающая сила организма, сила жизни 
                    </div>
                </div>

                <div className="spec-card">
                    <div className="spec-img-3"></div>

                    <div className="spec-title">
                    Массаж во врачевании во все времена играл огромную роль, и люди давно должны были поставить памятник рукам массажиста.
                    </div>
                </div>

                <div className="spec-card">
                    <div className="spec-img-4"></div>

                    <div className="spec-title">
                    Наше тело всё время говорит с нами. Если бы мы только нашли время послушать
                    </div>
                </div>
            </div>
        </main>
    </div>
  );
};

export default HomePage;