import React from 'react';
import Slider from 'react-slick';
import './massage.css'











const reviews = [
    {
        image: "./lpg-foto.jpg"
    },
    {
        image: "./lpg-foto1.jpg" 
        
    },
    {
        image: "./lpg-foto2.jpg" 
    },
    {
        image: "./lpg-foto3.jpg"
    },
    {
        image: "./lpg-foto5.jpg" 
        
    },
    {
        image: "./lpg-foto6.jpg" 
    },
    {
        image: "./lpg-foto8.jpg" 
    },
    {
        image: "./lpg-foto10.jpg" 
    },
    {
        image: "./lpg-foto11.jpg" 
    },
    {
        image: "./lpg-foto12.jpg" 
    },
    {
        image: "./lpg-foto13.jpg" 
    },
    // Добавьте другие отзывы сюда
];

const reviews2 = [
    {
        image: "./lpg-apparat.jpg"
    },
    {
        image: "./lpg-apparat1.jpg" 
        
    },
    {
        image: "./lpg-apparat2.jpg" 
    },
    // Добавьте другие отзывы сюда
];

const NextArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-next" onClick={onClick}>
            ›
        </div>
    );
};

const PrevArrow = (props) => {
    const { onClick } = props;
    return (
        <div className="slick-arrow slick-prev" onClick={onClick}>
            ‹
        </div>
    );
};
const massagelpg = ({title, titlee, handleAddToCart, cartItems, handleRemoveFromCart}) => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />
    };
    return (
        <div className="massage-page">
            <main className="main-block">
                <h1>LPG-массаж в {titlee}</h1>

                <h2 className='anti'>
                В результате положительного воздействия, вукуумный массаж тела придает ровные формы и эффективно влияет на уменьшение целлюлита, особое внимание заслуживает косметологический эффект, такой как:

                1.Уменьшение и лечение целлюлита до полного устранения его внешних проявлений.

                2.Избавление от локальных жировых отложений, от которых сложно избавиться при помощи физических нагрузок или диет.

                3.Подтяжка и улучшение эластичности кожи.

                4.Улучшение лимфотического оттока и кровообращения.

                5.Улучшение внешнего вида и общего состояния кожи.

                6.Устранение отеков и застоев в тканях.

                7.Несмотря на высокую эффективность, во время процедуры пациент не чувствует боли.

                8.Регулярные сеансы увеличивают упругость кожи и уменьшают растяжки.


                </h2>
            </main>

            <div className="review-slider" style={{height: 500}}>
              <Slider {...settings}>
                  {reviews.map((review, index) => (
                      <div key={index} className="review">
                          <img className='img-sl1' src={review.image} alt={`Отзыв ${index + 1}`} /> {/* Добавьте элемент img */}
                      </div>
                  ))}
              </Slider>
            </div>

            <main className="main-block">
                <h1>Аппараты для LPG массажа в {titlee}</h1>

                <h2 className='anti'>
                Для достижения наилучших результатов в аппаратной коррекции фигуры и лица применяются, аппараты Vela Shape-KES MED-360, Body integral V-9, Perfect-1000. Vela Shape-KES MED-360 единственный аппаратный антицеллюлитный массаж, при котором для достижения результата используется комбинированная технология Elos, объединяющая четыре процедуры в одну, обеспечивая жировым тканям достаточную тепловую энергию для эффективного и глубокого массажа тканей.
                <br />

                Body integral V-9 позволяет проводить сеанс как по специальному костюму, так и с применением масел для массажа.
                <br />
                Perfect-1000 — имеет 6 насадок для эндотермического(по костюму) массажа, может менять частоту вакуума и направление движения роликов.
                <br />
                <ul className='list' style={{ margin: 16, marginLeft: 0, marginRight:0, display:'flex', flexDirection:'column', gap:8 }}>
                    <li>Инфракрасные лучи (IR) прогревают слой кожи глубиной до 5 мм;</li>
                    <li>Стимулирующая LED терапия ускоряет регенерацию тканей, сокращает время восстановления.</li>
                    <li>Биполярные радиоволны (RF) воздействуют на жировые ткани на глубине от 2 до 20 мм;</li>
                    <li>Пульсирующий вакуум и механический роликовый массаж выравнивают верхний слой кожи и обеспечивают локальную подачу тепла для расщепления жировой ткани.  </li>
                </ul>
                
                
                
                              
                <br />
                Перед началом сеанса лпж массажа на аппаратах Vela Shape-KES MED-360 и Body integral V-9 нет необходимости использовать индивидуальные «эндермологические» костюмы, чтобы оценить все преимущества совмещения Elos технологий, которые позволяют в течение одной процедуры аппаратного массажа воздействовать как на глубокие жировые отложения, планомерно избавляя от целлюлита, так и уменьшать объемы тела и выравнивать верхний слой кожи посредством использования радиоволн. Поэтому, после процедуры кожа становится более гладкой и подтянутой.

                <br />
                Под воздействием инфракрасных лучей и радиоволн на жировые ткани, происходит нагревание, уменьшение и расщепление жировых клеток, после чего они лимфатическим путем выводятся из организма в процессе естественного метаболизма. При этом на участок тела одновременно воздействуют вакуумный и механический массаж роликами. Они стимулируют улучшение микроциркуляции, увеличивают лимфодренаж и помогают избавиться от побочных продуктов обмена веществ.
                <br />
                Радиоволны и инфракрасные лучи стимулируют возобновление синтеза новых волокон коллагена, укрепляют существующие волокна, сокращают количество жировых клеток, кожа подтягивается и становится более упругой.


                </h2>
            </main>

            
            <div className="review-slider" style={{height: 500}}>
              <Slider {...settings}>
                  {reviews2.map((review, index) => (
                      <div key={index} className="review">
                          <img className='img-sl1' src={review.image} alt={`Отзыв ${index + 1}`} /> {/* Добавьте элемент img */}
                      </div>
                  ))}
              </Slider>
          </div>
        </div>
    );
}

export default massagelpg;